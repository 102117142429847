import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import { messaging } from "../../../../init-fcm";
import axios from "axios";
//import {useGlobal} from "reactn"

function getOptions() {
  const storedData = localStorage.getItem('persist:eKinerjaLocal');
  let localData = JSON.parse(storedData);
  let options = {
    headers: {
      'token': ""
    }
  };
  if (localData['token']) {
    const storedData2 = localStorage.getItem('persist:eKinerjaLocal');
    let localData2 = JSON.parse(storedData2);
    let authToken = JSON.parse(localData2['token']);
    options = {
      headers: {
        'token': `${authToken}`
      }
    };
    return options
  } else {
    options = {
      headers: {
        'token': ""
      }
    };
    return options
  }
}


class Logout extends Component {

  componentDidMount() {
    this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;
    let option = getOptions()

    try {
      messaging.getToken()
      .then(async (token) => {
        const postData = {
          'token_firebase': `${token}`
        };

        axios.post('https://ekinerja.rembangkab.go.id/api/api/v1/auth/logout', postData, option)
          .then(res => {
          })
          .catch(err => {
            err.message = "Gagal Logout";
          });
      })
      return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
    } catch (error) {
      return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
    }
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
