import * as requestFromServer from "./customersCrud";
import {customersSlice, callTypes} from "./customersSlice";

const {actions} = customersSlice;

export const fetchCustomers = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  const qu = {
    filter: {
      // tanggal: "",
      activity: "",
      waktu_usul: "",
      bulan: "2021-01",
      waktu_approval: ""
    },
    sortOrder: "desc", // asc||desc
    sortField: "id",
    pageNumber: 1,
    pageSize: 1000
  }

  return requestFromServer
    .findCustomers(queryParams.all === true ? qu : queryParams)
    .then(response => {
      const { totalCount, entities, inputStatus, activityInfo, blokir_awal, blokir_akhir  } = response.data;
      dispatch(actions.customersFetched({ totalCount, entities, inputStatus, activityInfo, blokir_awal, blokir_akhir }));  
      return response.data;
    })
    .catch(error => {
      error.clientMessage = "Can't find customers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const downloadAktivitas = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  const qu = {
    filter: {
      // tanggal: "",
      activity: "",
      waktu_usul: "",
      bulan: queryParams.filter.bulan,
      waktu_approval: ""
    },
    sortOrder: "desc", // asc||desc
    sortField: "id",
    pageNumber: 1,
    pageSize: 1000
  }

  return requestFromServer
    .findCustomers(queryParams.all === true ? qu : queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.customersFetched({ totalCount, entities }));
      return response.data.entities;
    })
    .catch(error => {
      error.clientMessage = "Failed to Download";
    });
};

export const fetchCustomer = id => dispatch => {
  if (!id) {
    return dispatch(actions.customerFetched({ customerForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCustomerById(id)
    .then(response => {
      const customer = response.data;
      dispatch(actions.customerFetched({ customerForEdit: customer }));
    })
    .catch(error => {
      error.clientMessage = "Can't find customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomer = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomer(id)
    .then(response => {
      dispatch(actions.customerDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCustomer = customerForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCustomer(customerForCreation)
    .then(response => {
      console.log('response act --', response);
      const { customer } = response.data;
      
      // let responseService = {
      //   open : true,
      //   response : response.data
      // }

      if(response.data.status === 200) {
        dispatch(actions.customerCreated({ customer }));
        // return response.data.status;
        return response.data;
        // return responseService;
      } else {
        // return responseService;
        return response.data;
      }
    })
    .catch(error => {
      error.clientMessage = "Can't create aktivitas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      // const responseServer = {
      //   open : true,
      //   response : {
      //     message : "Server Error",
      //     status : 50199 
      //   }
      // }

      const responseServer = {
          message : "Server Error",
          status : 50199 
      }
      return responseServer;
    });
};

export const updateCustomer = customer => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCustomer(customer)
    .then(() => {
      dispatch(actions.customerUpdated({ customer }));
      console.log("---- update", customer);
      // if(response.data.status === 200) {
      //   dispatch(actions.customerCreated({ customer }));
      //   // return response.data.status;
      //   return response.data;
      // } else {
      //   return response.data;
      // }
    })
    .catch(error => {
      error.clientMessage = "Can't update customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      const responseServer = {
        message : "Server Error",
        status : 50199
      }
      return responseServer;
    });
};

export const updateCustomersStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCustomers(ids, status)
    .then((response) => {
      dispatch(actions.customersStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update acivity status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomers(ids)
    .then((response) => {
      dispatch(actions.customersDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete customers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const sendCustomer = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .sendCustomer(id)
    .then(response => {
      const customer = response.data.customer;
      dispatch(actions.customerSendUpdated({ customer }));
    })
    .catch(error => {
      error.clientMessage = "Can't send activiy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
