// import {useGlobal} from "reactn"
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";

import { login, getUserByToken  } from "../_redux/authCrud";
import { Responsive } from "responsive-react";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import { Needlogin } from "../../../GlobalState"

import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  nip: "",
  password: "",
  captcha: ""
};


function Login(props) {

  const [captchaLoaded, setCaptchaLoaded] = useState(false);

  useEffect(() => {
    // Load the captcha engine and set the captchaLoaded state to true
    loadCaptchaEnginge(6);
    setCaptchaLoaded(true);
  }, []);
  
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    nip: Yup.string()
      .min(3, "NIP atau Username harus lebih dari 3 digit")
      .max(18, "NIP atau Username kurang dari atau sama dengan 16 digit")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    captcha: Yup.string()
      .min(6, "Minimum 6 character")
      .max(6, "Maximum 6 character")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
    
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  // const [sesUser, setSesUser] = useGlobal('ses_User')
  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      if (!validateCaptcha(values.captcha)) {
        // Captcha validation failed
        // setSubmitting(true);
        disableLoading();
        setSubmitting(false);
        setStatus("Captcha validation failed");
        return;
      } else {
        setTimeout(() => {
          login(values.nip, values.password)
            .then(({ data: { token } }) => {
              disableLoading();
              props.login(token);
              // getUserByToken(token);
              getUserByToken(token)
              .then((response) => {
                if(response.data.status !== 401){
                  props.fulfillUser(response.data);
                  // setSesUser(response.data)
                  jajal()
                  // reloadLoginPage()
                  // console.log("-- hasil auth", response);
                }else{
                  setSubmitting(false);
                  setStatus(
                    intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_LOGIN",
                    })
                  );
                }
              });
            })
            .catch(() => {
              disableLoading();
              setSubmitting(false);
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );
            });
        }, 1000);
      }
      
    },
  });
function jajal(){
  return Needlogin
}

// function reloadLoginPage() {
//   return (
//     (window.location.href = "/")
//   );
// }
  return (
    
    <>
    <Responsive displayIn={["Laptop"]}>
      <div className="login-form login-signin" id="kt_login_signin_form">
        {/* begin::Head */}
        <div className="text-center mb-10 mb-lg-20">
          <h1 className="font-size-h1">
            {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
            Login eKinerja
          </h1>
        </div>
        {/* end::Head */}

        {/*begin::Form*/}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          {formik.status ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : (
            <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
              <div className="alert-text">
                Masukan <strong className="text-dark-70">NIP</strong> dan <strong className="text-dark-70">Password</strong> untuk mengakses Aplikasi eKinerja.
              </div>
            </div>
          )}

          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="nip">NIP</label>
            <input
              placeholder="NIP"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "nip"
              )}`}
              name="nip"
              id="nip"
              {...formik.getFieldProps("nip")}
            />
            {formik.touched.nip && formik.errors.nip ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.nip}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <label className="form-label" htmlFor="password">Password</label>
            <input
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              id="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          

          <div className="form-group fv-plugins-icon-container">
            <div className="row">
              <div className="col-lg-6">
                <LoadCanvasTemplate />
              </div>
              <div className="col-lg-6">
                {captchaLoaded ? (
                  <input
                    placeholder="Captcha"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${
                      getInputClasses("captcha")
                    }`}
                    name="captcha"
                    id="captcha"
                    {...formik.getFieldProps("captcha")}
                  />
                ) : null}
                {formik.touched.captcha && formik.errors.captcha ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.captcha}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <Link
              // to="/auth/forgot-password"
              to="#"
              className="text-dark-50 text-hover-primary my-3 mr-2"
              id="kt_login_forgot"
            >
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              <span className="text-dark-70">Sign In</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>
        {/*end::Form*/}
      </div>
    </Responsive>
    <Responsive displayIn={["Mobile", "Tablet"]}>
    <div className="login-form login-signin h-100" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-2 mb-lg-20">
        <img
            alt="Logo"
            className="max-h-70px"
            src={toAbsoluteUrl("/media/logos/rembang.png")}
        />
        <h1 className="font-size-h1 text-white mt-5">
          {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
          Login eKinerja Rembang
        </h1>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <>
          </>
        )}

        <div className="form-group fv-plugins-icon-container">
          <label className="form-label text-white" htmlFor="nip">NIP</label>
          <input
            placeholder="NIP"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "nip"
            )}`}
            name="nip"
            id="nip"
            {...formik.getFieldProps("nip")}
          />
          {formik.touched.nip && formik.errors.nip ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.nip}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label className="form-label text-white" htmlFor="password">Password</label>
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            id="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
            <div className="row">
              <div className="col-sm-6">
                <LoadCanvasTemplate />
              </div>
              <div className="col-sm-6">
                {captchaLoaded ? (
                  <input
                    placeholder="Captcha"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${
                      getInputClasses("captcha")
                    }`}
                    name="captcha"
                    id="captcha"
                    {...formik.getFieldProps("captcha")}
                  />
                ) : null}
                {formik.touched.captcha && formik.errors.captcha ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.captcha}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            // to="/auth/forgot-password"
            to="#"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  </Responsive>
  </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
