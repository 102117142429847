/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import SVG from "react-inlinesvg";
import {Dropdown} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_helpers";
import {DropdownCustomToggler} from "../../dropdowns";
import axios from 'axios';
import {useSelector} from "react-redux";
import { isExpired } from "react-jwt";

const moment = require('moment')
moment.locale('ID')

export function ListsWidget1({ className }) {
  const {token} = useSelector(state => state.auth);
 
  const [listAktv1, setListAktv1] = useState([]);

  // const tokenSayaExpired = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmNzFkM2E5MjQzYTAwMDBhZTAwNmU1YyIsIm5pcCI6IjE5NzcwOTIyMTk5NzAzMTAwMyIsImlhdCI6MTYwNDMyMTk5MCwiZXhwIjoxNjA2OTEzOTkwLCJhdWQiOiJVc2Vyc0lkZW50aXR5Iiwic3ViIjoic2Vzc2lvbkp3dFNhdmUifQ.GfkKFYhOg9vka9Iv77iXTdr9n_EXtcrJ9Ww3IpbL9d4";

  useEffect(() => {
    let resultToken = isExpired(token);

    if(resultToken === false) {
      load();
    } else {
      console.log("** tanpa load axios 2")
    }
  }, []);

  function load(){
    let optionsResume = {
      headers: {
        'token': `${token}`
      }
    };
    let hasil=[];
    axios.get(`https://ekinerja.rembangkab.go.id/api/api/v1/dashboard/resumeActivity`,optionsResume).then(res => {
        hasil=(res.data);
        setListAktv1(hasil);
      
      }).catch(err => {
      
    });
  }
  // console.log("--> widget1 ", listAktv1);

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Laporan Kinerja Overview
          </h3>
          <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                variant="transparent"
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
               >
                <i className="ki ki-bold-more-hor" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                {/* <DropdownMenu4 /> */}
              </Dropdown.Menu>
            </Dropdown>            
          </div>
        </div>

        {/* Body */}
        <div className="card-body pt-2">
          <div className="d-flex align-items-center mb-10">
            <div className="symbol symbol-40 symbol-light-warning mr-5">
            <span className="symbol-label">
                <span className="svg-icon svg-icon-lg svg-icon-warning">
                  <SVG
                    className="h-75 align-self-end"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  ></SVG>
                </span>
              </span>
            
          </div>

            <div className="d-flex flex-column font-weight-bold">
              <a
                href="/aktivitas/"
                className="text-dark text-hover-warning mb-1 font-size-lg"
              >
                Usulan Kinerja Draft 
              </a>
                <span className="font-size-sm text-warning text-hover-muted">Draft : {listAktv1.draft} usulan ({listAktv1.waktu_draft}) menit </span>
            </div>
          </div>

          <div className="d-flex align-items-center mb-10">
            <div className="symbol symbol-40 symbol-light-primary mr-5">
            <span className="symbol-label">
                <span className="svg-icon svg-icon-lg svg-icon-primary">
                  <SVG
                    className="h-75 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                  ></SVG>
                </span>
              </span>
                     
            </div>
            <div className="d-flex flex-column font-weight-bold">
              <a
                href="#"
                className="text-dark-75 text-hover-primary mb-1 font-size-lg"
              >
                Usulan Kinerja Terkirim 
              </a>
              <span className="font-size-sm text-primary text-hover-muted">Terkirim : {listAktv1.terkirim} usulan ({listAktv1.waktu_terkirim}) menit </span>
            </div>
          </div>
          <div className="d-flex align-items-center mb-10">
            <div className="symbol symbol-40 symbol-light-success mr-5">
              <span className="symbol-label">
                <span className="svg-icon svg-icon-lg svg-icon-success">
                  <SVG
                    className="h-75 align-self-end"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group-chat.svg"
                    )}
                  ></SVG>
                </span>
              </span>
            </div>

            <div className="d-flex flex-column font-weight-bold">
              <a
                href="#"
                className="text-dark text-hover-primary mb-1 font-size-lg"
              >
                 Usulan Kinerja Disetujui 
              </a>
              <span className="font-size-sm text-primary text-hover-muted">Disetujui : {listAktv1.setujui} usulan ({listAktv1.waktu_setujui}) menit </span>

            </div>
          </div>

          <div className="d-flex align-items-center mb-10">
            <div className="symbol symbol-40 symbol-light-danger mr-5">
              <span className="symbol-label">
                <span className="svg-icon svg-icon-lg svg-icon-danger">
                  <SVG
                    className="h-75 align-self-end"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Attachment2.svg"
                    )}
                  ></SVG>
                </span>
              </span>
            </div>

            <div className="d-flex flex-column font-weight-bold">
              <a
                href="#"
                className="text-dark text-hover-primary mb-1 font-size-lg"
              >
                 Usulan Kinerja Ditolak
              </a>
              <span className="font-size-sm text-danger text-hover-muted">Ditolak : {listAktv1.tolak} usulan ({listAktv1.waktu_tolak}) menit </span>

            </div>
          </div>

        </div>
      </div>
    </>
  );
}
