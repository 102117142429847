export const numberFormat = (value) => new Intl.NumberFormat().format(value);

export function GetResume(p) {
  if(p === undefined ){return {}}
  let hasil = p.kinerja;
  let jml = hasil.length;
  let jmlUsul = 0;
  for (let i = 0; i < jml; i++) {
    jmlUsul = jmlUsul + hasil[i][2];
  }
  let mode_tpp = p.mode_tpp; if (mode_tpp === null) { mode_tpp = 100; }
  mode_tpp = parseFloat(mode_tpp);
  let itung = ItungJabatan(p.jabatan);
  let absen = ItungAbsen(p.presensi);
  let resume_kinerja = parseFloat(p.resume[6]);
  let kinerja_total = 0;
  let individu_tpp_basis = parseFloat(p.jmlh_tpp);

  // perhitungan Kinerja seuai mode TPP
  if (itung.kinerja_struktural === 1) {
    if (itung.kinerja_bwh_jml > 0) {
      kinerja_total = (resume_kinerja * mode_tpp / 100) + (itung.kinerja_bwh_prosen * (100 - mode_tpp) / 100);

    } else {
      kinerja_total = (resume_kinerja * mode_tpp / 100) + (100 * (100 - mode_tpp) / 100);
    }
  } else {
    kinerja_total = parseFloat(resume_kinerja);
  }
  //  Perhitungan Uang yang diterima sesuai tpp
  let individu_plt = 0;
  if (itung.jab_kalkulasi === "plt_sejajar") {
    individu_plt = p.tpp_plt;
  }
  let kinerja_rupiah = 60 / 100 * (p.jmlh_tpp * kinerja_total) / 100;
  //perhitungan PRESENSI
  let presensi_rupiah = 40 / 100 * (p.jmlh_tpp * absen.presensi_persenTotal) / 100;
  //  HASIL PERHITUNGAN

  //console.log("presensi_rupiah",presensi_rupiah);
  return ({
    nama: p.nama,
    nip: p.nip,
    tpp_total: (p.jmlh_tpp + p.tpp_plt),
    jml_jabatan: p.jabatan.length,
    jml_usulan: p.resume[1],
    jml_staf_kinerja: itung.kinerja_bwh_jml,
    kinerja_resume: {
      prosentase: 60,
      individu_mode: mode_tpp,                         //100%,60%,40%,0% sesuai ketentuan 
      individu_prosen: resume_kinerja,                 //% capaian individu yg disetujui 
      bawahan_mode: (100 - mode_tpp),                    //100%,60%,40%,0% sesuai ketentuan, sisa atasan
      bawahan_jumlah: itung.kinerja_bwh_jml,           //Jumlah Bawahan
      bawahan_prosen: itung.kinerja_bwh_prosen,        //persen Rata-rata bawahan 
      capaian_prosen: kinerja_total,
      capaian_rupiah: kinerja_rupiah,
      tpp_basis: individu_tpp_basis,                   //jumlah TPP Basis
      tpp_kinerja: kinerja_rupiah,                    //jumlah TPP Basis
      tpp_bawahan: (itung.kinerja_bwh_prosen * (100 - mode_tpp) / 100), //persen Rata-rata bawahan 
      tpp_plt: individu_plt,                           //jumlah TPP PLT
    },

    presensi_resume: {
      prosentase: 40,
      _A: absen.presensi_A,
      _T: absen.presensi_T,
      _persenA: absen.presensi_persenA,
      _persenT: absen.presensi_persenT,
      _persenTotal: absen.presensi_persenTotal,
      _rupiah: presensi_rupiah,
    },

    // kinerja_personal_prosen: resume_kinerja,
    // kinerja_bwh_prosen: itung.kinerja_bwh_prosen,
    // kinerja_total: kinerja_total,
    // kinerja_rupiah: kinerja_rupiah,
    // presensi_A: absen.presensi_A,
    // presensi_T: absen.presensi_T,
    // presensi_persenA: absen.presensi_persenA,
    // presensi_persenT: absen.presensi_persenT,
    // presensi_persenTotal: absen.presensi_persenTotal,
    // presensi_rupiah: presensi_rupiah,
    jab_jadi: itung.jab_jadi,
  }
  )
}

function ItungAbsen(a) {
  let presensi_persenA = 0;
  let presensi_persenT = 0;
  let presensi_persenTotal = 0;
  let presensi_A = 0;
  let presensi_T = 0;

  if (a.length > 0) {
    for (let i = 0; i < a.length; i++) {
      if (a[i].kode === "T") { presensi_T = presensi_T + 1 }
      presensi_A = presensi_A + a[i].akumulasi;
    }
  }
  presensi_persenA = parseFloat(parseInt(presensi_A / 60) * 0.5)
  presensi_persenT = (presensi_T * 4);
  presensi_persenTotal = 100 - (presensi_persenA + presensi_persenT);


  return ({
    presensi_A: presensi_A,
    presensi_T: presensi_T,
    presensi_persenA: presensi_persenA,
    presensi_persenT: presensi_persenT,
    presensi_persenTotal: presensi_persenTotal,
  }

  )

}

function ItungJabatan(j) {
  let jab_def = j[0];
  let jab_plt = {};
  let jab_jadi = j[0];
  let jab_kalkulasi = "non_struktural"
  // menentukan Model Kalkulasi berdasar Jabatan yang dimiliki

  if (j.length > 1) {

    j.map((object, i) => (
      j[i].jab_statusjab < 3 ? jab_def = j[i] : j[i].jab_statusjab === 3 ? jab_plt = j[i] : ""
    ))

    if (jab_plt !== {} && jab_def !== {}) {
      if (jab_plt.jab_kelas > jab_def.jab_kelas) {
        jab_jadi = jab_plt; jab_kalkulasi = "plt_naik";
      } else {
        jab_jadi = jab_def;
        jab_kalkulasi = "plt_sejajar";
      }
    } else {
      jab_jadi = jab_def;
    }

  } else {
    jab_jadi = jab_def;
  }
  // Menentukan rata-rata Kinerja bawahan
  let kinerja_bwh_jml = 0;
  let kinerja_bwh_prosen = 0;
  let kinerja_struktural = 0;
  let mm = jab_jadi.jab_kode;
 
  // console.log ("--hh", jab_jadi);
  if (mm.substring(0, 1) === "1") {
    jab_kalkulasi = "pj_struktural"
    kinerja_struktural = 1;
    if (jab_jadi.bawahan.length < 1) {
      kinerja_bwh_jml = 0;
      kinerja_bwh_prosen = 100;
    } else if (jab_jadi.bawahan.length === 1) {
      kinerja_bwh_jml = 1;
      kinerja_bwh_prosen = jab_jadi.bawahan[0].resume[7];
    } else {
      let kn = 0; let jml = jab_jadi.bawahan.length
      for (let i = 0; i <= jml - 1; i++) {

        kn = kn + parseFloat(jab_jadi.bawahan[i].resume[7]);

      }
      kinerja_bwh_jml = jml;
      kinerja_bwh_prosen = kn / jml;
    }
  } else {
    kinerja_bwh_jml = 0;
    kinerja_bwh_prosen = 0;
  }

  //console.log("jadiii", kinerja_bwh_prosen);

  return {
    jab_jadi: jab_jadi,
    jab_kalkulasi: jab_kalkulasi,
    kinerja_bwh_jml: kinerja_bwh_jml,
    kinerja_bwh_prosen: kinerja_bwh_prosen,
    kinerja_struktural: kinerja_struktural,
  }
}
// / dash enam 
// export function DataMixedWidgetJadi() {
//   {jab_kode: "1400202000", jab_nama: "KEPALA SEKSI PENGELOLAAN KOMUNIKASI PUBLIK ", jab_opd: "1400000000", jab_statusjab: 3, jab_kelas: 8, …}bawahan: (2) [{…}, {…}]jab_kelas: 8jab_kode: "1400202000"jab_nama: "KEPALA SEKSI PENGELOLAAN KOMUNIKASI PUBLIK "jab_opd: "1400000000"jab_statusjab: 3__proto__: Object

// }
let initResume =[0,"",0,0,0,0,0,0,0]
let initKinerja=[0,"2020-10-13",300,0,1,0,0,0,0]

export function mockDP() {
  return [
    {
      "id": 5351,
      "nip": "0000000000000000000",
      "profile": [
          {
              "nip": "0000000000000000000",
              "nama": "NAMA ASN",
              "mode_tpp": 0,
              "jmlh_tpp": 0,
              "tpp_plt": 0,
              "jmlh_hari_kerja": 5
          }
      ],
      "resume": [
          0,
          "",
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "jabatanTPP": [
          {
              "jab_kode": "0000000000",
              "jab_statusjab": 1,
              "jab_nama": "JABATAN ASN",
              "jab_kelas": "",
              "jab_eselon": 22,
               "atasan":[
                {
                    "nama": " ",
                    "nip": " ",
                    "jabatan": " "
                }
                 ],
              "jab_kalkulasi": "pj_struktural",
              "kinerja_struktural": 1
          }
      ],
      "jabatan": [
          {
              "jab_kode": "4200000000",
              "jab_statusjab": 1,
              "jab_nama": "jabatan asn",
              "jab_kelas": "",
              "jab_eselon": 22,
              "atasan":[
                {
                    "nama": "Atsann 2 ",
                    "nip": "Nip Atasan2 ",
                    "jabatan": "Jab Atasn "
                }
                 ],
              "jab_kalkulasi": "pj_struktural",
              "kinerja_struktural": 1
          }
      ],
      "bawahan": [
          {
              "mode_tpp": 40,
              "jmlh_hari_kerja": 5,
              "nip": "000000000000000000000000",
              "nama": "BAWAHAN 1",
              "resume": [
                  0,
                  "",
                  6879,
                  6879,
                  58,
                  58,
                  5889,
                  89.23,
                  0
              ]
          }
      ],
      "presensi": [
          {
              "presensi_A": 0,
              "presensi_T": 0,
              "presensi_persenA": 0,
              "presensi_persenT": 0,
              "presensi_persenTotal": 100,
              "status_hukaman_disiplin": 100
          }
      ],
      "reportFinal": [
          {
              "prosentase": 60,
              "individu_mode": 0,
              "individu_prosen": 0,
              "bawahan_mode": 0,
              "bawahan_jumlah": 0,
              "bawahan_prosen": 0,
              "capaian_prosen_total": 0,
              "tpp_basis": 0,
              "tpp_kinerja": 0,
              "tpp_bawahan": 0,
              "tpp_presensi": 0,
              "tpp_total": 0,
              "tpp_plt": 0,
              "tpp_kinerja_final": 0,
              "tpp_plt_total": 0
          }
      ]
  }
]
}

export function DataMixedWidget1() {
  return [
    {
        "mode_tpp": 100,
        "jmlh_hari_kerja": 5,
        "jmlh_tpp": 1000000,
        "tpp_plt": 0,
        "nip": "NIP PEGAWAI",
        "nama": "NAMA PEGAWAI",
        "kinerja": initKinerja,
        "resume": initResume,
        "resumefinal": {
          "nip": "NIP PEGAWAI",
          "nama": "NAMA PEGAWAI",
            "tpp_total": 1000000,
            "jml_jabatan": 1,
            "jml_usulan": 0,
            "jml_staf_kinerja": 0,
            "kinerja_resume": {
                "prosentase": 60,
                "individu_mode": 100,
                "individu_prosen": 0,
                "bawahan_mode": 0,
                "bawahan_jumlah": 0,
                "bawahan_prosen": 0,
                "capaian_prosen": 0,
                "capaian_rupiah": 0,
                "tpp_basis": 1000000,
                "tpp_kinerja": 0,
                "tpp_bawahan": 0,
                "tpp_plt": 0
            },
            "presensi_resume": {
                "prosentase": 40,
                "_A": 0,
                "_T": 0,
                "_persenA": 0,
                "_persenT": 0,
                "_persenTotal": 100,
                "_rupiah": 400000
            },
            "jab_jadi": {
                "jab_kode": "40497",
                "jab_nama": "JAB NAMA",
                "jab_opd": null,
                "jab_statusjab": 1,
                "jab_kelas": 5,
                "bawahan": [],
                "atasan": {
                  "nip": "000",
                  "nama": "000",
                  "pangkat_gol": "9999",
                  "jabatan": "00",
                  "unit_kerja": "00"
              }
            }
        },
        "jabatan": [
            {
                "jab_kode": "4999",
                "jab_nama": "JABATAN BELUM TERSEDIA, hub OPERATOR",
                "jab_opd": null,
                "jab_statusjab": 1,
                "jab_kelas": 7,
                "bawahan": [],
                "atasan": {
                  "nip": "000",
                  "nama": "000",
                  "pangkat_gol": "9999",
                  "jabatan": "00",
                  "unit_kerja": "00"
              }
            }
        ],
        "presensi": [
            {
                "id_log": 11719573,
                "tanggal": "2020-10-01",
                "masuk": "  ",
                "pulang": "  ",
                "akumulasi": 0,
                "is_absence": 0,
                "kode": "TB",
                "keterangan": "Tugas Belajar--0"
            },
        ]
    }
]
}
export function getChartOptionsDashEnam(layoutProps, chartInput, Yaxis) {
  const strokeColor = "#D13647";
  //const strokeColor = "#365fd1";
  // let dataValue = [0, 0, 0, 120, 40, 40, 40];
  // let dataCategory = ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"];
  let mYaxis = Yaxis;
  let dataValue = chartInput[0];
  let dataCategory = chartInput[1];

  const options = {
    series: [
      {
        name: "Usulan Kinerja ",
        data: dataValue
      }
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 0
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [strokeColor]
    },
    xaxis: {
      categories: dataCategory,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      }
    },
    yaxis: {
      min: 0,
      max: mYaxis,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function (val) {
          return val + " mnt";
        }
      },
      marker: {
        show: false
      }
    },
    colors: ["transparent"],
    markers: {
      colors: layoutProps.colorsThemeBaseDanger,
      strokeColor: [strokeColor],
      strokeWidth: 3
    }
  };
  return options;
}

// DASH SATU
export function getChartOptionsSatu(layoutProps, height, nilai) {
  let nn = nilai;
  const options = {
    series: [nn],
    chart: {
      height: height,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 10,
          size: "55%"
        },
        dataLabels: {
          showOn: "always",
          name: {
            show: false,
            fontWeight: "400",
          },
          value: {
            color: layoutProps.colorsGrayGray700,
            fontSize: "30px",
            fontWeight: "700",
            offsetY: 12,
            show: true
          },
        },
        track: {
          background: layoutProps.colorsThemeLightSuccess,
          strokeWidth: '100%',
          margin: 5,
        }
      }
    },
    colors: [layoutProps.colorsThemeBaseWarning],
    stroke: {
      // lineCap: "round",
    },
    labels: ["Progress"]
  };
  return options;
}