import React, { Suspense, lazy } from "react";
import { useGlobal, setGlobal } from "reactn";
import {useSelector,useDispatch,shallowEqual} from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import {Needlogin} from "./GlobalState"

const Data = lazy(() => import("./modules/Data/pages/dataPage"));
const MasterPage = lazy(() => import("./modules/Master/pages/MasterPage"));
const AdminPemkabPage = lazy(() => import("./modules/Adminpemkab/pages/AdminPemkabPage"));
const ResumePresensi = lazy(() => import("./modules/Resume/_PresensiPage"));
const ResumeKinerja = lazy(() => import("./modules/Resume/_KinerjaPage"));

const UserProfilepage = lazy(() =>
    import("./modules/UserProfile/UserProfilePage")
);
const moment = require('moment')
const Auth = lazy(() => import("./modules/Auth"))



export default function BasePage() {
    const [sesUser, setSesUser] = useGlobal('ses_User')
    // const { myperiode } = useSelector((state) => state.auth);
    // dispatch({type:'SetMyperiode',payload:'6666666666'})
    // const { myperiode } = useSelector((state) => ({ currentUser: state.auth }),shallowEqual);
    // console.log("myperiode--1", myperiode)
    // const dispatch = useDispatch()
    
    // dispatch(actions.SetMyperiode("EMBUH"));


    // console.log("myperiode--2", myperiode)


    // const myperiode = useSelector( (state: auth) => state.myperiode)
    
    // let cekPeriode = JSON.parse(localStorage.getItem('persist:eKinerjaLocal'));
    // if(cekPeriode === null || cekPeriode === "" || cekPeriode === undefined ){
    //     localStorage.setItem('periode',JSON.stringify(myperiode));
    // }
    
   
    if (sesUser == undefined) {
        let cek=Needlogin()
    }

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                   
                    <Redirect exact from="/" to="/dashboard" />
                }
                <ContentRoute path="/dashboard" component={DashboardPage} />

                <Route path="/data/aktivitas" component={Data} />
                <Route path="/data/reportaktivitas" component={ResumeKinerja} />

                <Route path="/penilaian/aktivitas" component={Data} />
                <Route path="/penilaian/resume" component={ResumeKinerja} />

                <Route path="/master" component={MasterPage} />
                {/* <Route path="/master/kinerja" component={KinerjaOPD}/>
                 */}
                <Route path="/adminpemkab" component={AdminPemkabPage} />
                <Route path="/presensi" component={ResumePresensi} />

                {/* <Route path="/aktivitas/reportaktivitas/" component={ResumeKinerja}/> */}

                <Route path="/util" component={Auth} />
                <Route path="/user-profile" component={UserProfilepage} />

                {/* <Route path="/data" component={Admin}/> */}
                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
}