import React from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import WorkIcon from '@material-ui/icons/Work';
import FingerprintSharpIcon from '@material-ui/icons/FingerprintSharp';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import SupervisorAccountSharpIcon from '@material-ui/icons/SupervisorAccountSharp';
import { Link } from 'react-router-dom';
import {useLocation} from "react-router";
import {checkIsActive} from "../../../_helpers";



const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: '1',
  },
});

export function Footer() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu } Mui-selected `
        : "";
  };

  const { user } = useSelector(state => state.auth);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={`${classes.root} ${user.role_group == "operator" || user.role_group == "admin" ? 'd-none' : "d-block"}`}
    >
      <BottomNavigationAction label="Home" className={`pr-0 ${getMenuItemActive("/dashboard", false)}`} component={Link} to="/dashboard" icon={<HomeIcon />} />
      <BottomNavigationAction label="Aktivitas" className={`${getMenuItemActive("/data/aktivitas", false)}`} component={Link} to="/data/aktivitas" icon={<WorkIcon />} />
      <BottomNavigationAction label="Presensi" className={`${getMenuItemActive("/presensi/log", false)}`} component={Link} to="/presensi/log" icon={<FingerprintSharpIcon />} />
      <BottomNavigationAction label="Penilaian" className={`${getMenuItemActive("/penilaian/aktivitas", false)}`} component={Link} to="/penilaian/aktivitas" icon={<SupervisorAccountSharpIcon />} />
      <BottomNavigationAction label="Profil" className={`${getMenuItemActive("/user-profile/profile-overview", false)}`} component={Link} to="/user-profile/profile-overview" icon={<PersonIcon />} />
    </BottomNavigation>
  );
}
