/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useEffect} from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import OfflinePage from "./modules/Offline/OfflinePage";
import { isExpired } from "react-jwt";

// require('dotenv').config()
// const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE
// const { PUBLIC_URL } = process.env
// const B_URL = process.env.REACT_APP_API

// const tokenSayaExpired = "eyJhbGciOiJIzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmNzFkM2E5MjQzYTAwMDBhZTAwNmU1YyIsIm5pcCI6IjE5NzcwOTIyMTk5NzAzMTAwMyIsImlhdCI6MTYwNDMyMTk5MCwiZXhwIjoxNjA2OTEzOTkwLCJhdWQiOiJVc2Vyc0lkZW50aXR5Iiwic3ViIjoic2Vzc2lvbkp3dFNhdmUifQ.GfkKFYhOg9vka9Iv77iXTdr9n_EXtcrJ9Ww3IpbL9d4";

export function Routes() {

    const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.token != null && auth.user != null,
        }),
        shallowEqual
    );
    // const {profiletoken, profileuser} = useSelector(
    //     ({auth}) => ({
    //         profiletoken :auth.token,
    //         profileuser :auth.user,
    //     }),
    //     shallowEqual
    // );
    
    const history = useHistory();

    const { token } = useSelector((state) => state.auth);

    const isMyTokenExpired = isExpired(token);
    // const isMyTokenExpired = isExpired(tokenSayaExpired);
    // console.log("-- token expired : ", isMyTokenExpired)
    // console.log("-- isAuthorized : ", isAuthorized)
    
    useEffect(() => {
        CheckToken();
        // console.log("-- maintenance 1 x:", maintenanceMode);
        // console.log("-- maintenance 2 x:", PUBLIC_URL);
        // console.log("-- maintenance 3 x:", B_URL);
    }, []);

    const CheckToken = (props) => {
        if (isAuthorized === true) {
            if (isMyTokenExpired === true) {
                history.push("/logout");
            }
        }
    };
        
    return (
        <Switch>          
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/"/>
            )}

            <Route path="/logout" component={Logout}/>
            <Route path="/offline" component={OfflinePage}/>
            <Route path="/error" component={ErrorsPage}/> 
           
            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login"/>
            ) : (                 
                <Layout>
                    <BasePage/>
                </Layout>
            ) }
        </Switch>
    );
}
