/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_helpers";
import {useSelector} from "react-redux";

export function QuickIjin() {

  return (
    <div className="d-flex align-items-center flex-wrap mb-5">
      <div className="symbol symbol-50 symbol-light mr-3">
        <div className="symbol symbol-50 mr-1">
          <div className="symbol-label">
            <i className="flaticon-notepad text-primary icon-lg"></i>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-grow-1 mr-2">
        <a
            href="#"
            className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
        >
          Top Authors
        </a>
        <span className="text-muted font-weight-bold">
          Most Successful Fellas
        </span>
      </div>
      <span className="btn btn-sm btn-light font-weight-bolder py-1 my-lg-0 my-2 text-dark-50">
        +82$
      </span>
    </div>
  );
}
