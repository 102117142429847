/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ContentRoute} from "../../../../_metronic/layout"
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { Responsive } from "responsive-react";
import packageJson from '../../../../package.alias.json';

export function AuthPage() {
  
  return (
      <>
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
              className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
              id="kt_login"
          >
            {/*begin::Aside*/}
            <Responsive displayIn={["Laptop"]}>
            <div
                className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-1.jpg")})`
                }}
            >
              {/*begin: Aside Container*/}
              <div className="d-flex flex-row-fluid flex-column justify-content-between">
                {/* start:: Aside header */}
                <Link to="/" className="flex-column-auto mt-5">
                  <img
                      alt="Logo"
                      className="max-h-70px"
                      src={toAbsoluteUrl("/media/logos/rembang.png")}
                  />
                   <img
                      alt="Logo"
                      className="max-h-70px"
                      src={toAbsoluteUrl("/media/logos/logo-light.png")}
                  />
                </Link>
                {/* end:: Aside header */}

                {/* start:: Aside content */}
                <div className="flex-column-fluid d-flex flex-column justify-content-center">
                  <h3 className="font-size-h1 mb-5 text-white">
                    Selamat Datang di Aplikasi eKinerja
                  </h3>
                  <h4 className="font-size-h2 mb-5 text-white">Pemerintah Kab. Rembang</h4>
                  <p className="font-weight-lighter text-white opacity-80">
                    eKinerja adalah Sistem Elektronik Penilaian Kinerja Pegawai pada lingkungan Pemerintah Kabupaten Rembang. eKinerja digunakan oleh Pemerintah Kabupaten Rembang untuk mengelola, menilai dan mengevaluasi kinerja Pegawai Negeri Sipil (PNS) dalam kurun waktu tertentu.
                  </p>
                </div>
                {/* end:: Aside content */}

                {/* start:: Aside footer for desktop */}
                <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                  <div className="opacity-70 font-weight-bold	text-white">
                    &copy; 2020 - 2024 eKinerja Versi {packageJson.version} <br /> Dinas Komunikasi dan Informatika Kab. Rembang
                  </div>
                  <div className="d-flex">
                    {/* <Link to="/terms" className="text-white">
                      Privacy
                    </Link>
                    <Link to="/terms" className="text-white ml-10">
                      Legal
                    </Link>
                    <Link to="/terms" className="text-white ml-10">
                      Contact
                    </Link> */}
                  </div>
                </div>
                {/* end:: Aside footer for desktop */}
              </div>
              {/*end: Aside Container*/}
            </div>
            </Responsive>
            {/*begin::Aside*/}

            {/*begin::Content*/}
            <Responsive displayIn={["Laptop"]}>
              <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden" >
                {/*begin::Content header*/}
                {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                  <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
                  <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
                </div> */}
                {/*end::Content header*/}

                {/* begin::Content body */}
                <div className="d-flex flex-column-fluid flex-center mt-10 mt-lg-0">
                  <Switch>
                  <ContentRoute path="/auth/login" component={Login}/>
                  <ContentRoute path="/auth/registration" component={Registration}/>
                  <ContentRoute
                      path="/auth/forgot-password"
                      component={ForgotPassword}
                  />
                  <Redirect from="/auth" exact={true} to="/auth/login"/>
                  <Redirect to="/auth/login"/>
                </Switch>
                </div>
                {/*end::Content body*/}

                {/* begin::Mobile footer */}
                <div
                    className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                  <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2 text-center d-block">
                    &copy; 2020 - 2024 Pemerintah Kab. Rembang <br /> Dinas Kominfo Kab. Rembang<br />Versi {packageJson.version}
                  </div>
                  <div className="d-flex order-1 order-sm-2 my-2">
                    {/* <Link to="/terms" className="text-dark-75 text-hover-primary">
                      Privacy
                    </Link>
                    <Link
                        to="/terms"
                        className="text-dark-75 text-hover-primary ml-4"
                    >
                      Legal
                    </Link>
                    <Link
                        to="/terms"
                        className="text-dark-75 text-hover-primary ml-4"
                    >
                      Contact
                    </Link> */}
                  </div>
                </div>
                {/* end::Mobile footer */}
              </div>
            </Responsive>
            <Responsive displayIn={["Mobile", "Tablet"]}>
              <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-1.jpg")})`
                }}>
                {/*begin::Content header*/}
                {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                  <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
                  <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
                </div> */}
                {/*end::Content header*/}

                {/* begin::Content body */}
                <div className="d-flex flex-column-fluid flex-center mt-2 mt-lg-0">
                  <Switch>
                  <ContentRoute path="/auth/login" component={Login}/>
                  <ContentRoute path="/auth/registration" component={Registration}/>
                  <ContentRoute
                      path="/auth/forgot-password"
                      component={ForgotPassword}
                  />
                  <Redirect from="/auth" exact={true} to="/auth/login"/>
                  <Redirect to="/auth/login"/>
                </Switch>
                </div>
                {/*end::Content body*/}

                {/* begin::Mobile footer */}
                <div
                    className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                  <div className="text-white font-weight-bold order-2 order-sm-1 my-2 text-center">
                  &copy; 2020 - 2024 Pemerintah Kab. Rembang <br /> Dinas Kominfo Kab. Rembang<br />Versi {packageJson.version}
                  </div>
                  <div className="d-flex order-1 order-sm-2 my-2">
                    {/* <Link to="/terms" className="text-white text-hover-primary">
                      Privacy
                    </Link>
                    <Link
                        to="/terms"
                        className="text-white text-hover-primary ml-4"
                    >
                      Legal
                    </Link>
                    <Link
                        to="/terms"
                        className="text-white text-hover-primary ml-4"
                    >
                      Contact
                    </Link> */}
                  </div>
                </div>
                {/* end::Mobile footer */}
              </div>
            </Responsive>
            {/*end::Content*/}
          </div>
          {/*end::Login*/}
        </div>
      </>
  );
}
