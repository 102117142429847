import axios from "axios";

export const LOGIN_URL = "https://ekinerja.rembangkab.go.id/api/api/v1/auth/signIn";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = "https://ekinerja.rembangkab.go.id/api/api/v1/auth/users/info";

export function login(nip, password) {
  return axios.post(LOGIN_URL, { nip, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(token) {
    return axios.get(ME_URL, {
    headers: {
      'token': `${token}`
    }
  });

   
}

export function saveTokenFirebase(payload) {
  const localData = JSON.parse(localStorage.getItem('persist:eKinerjaLocal'));
  const authToken = JSON.parse(localData['token']);
  const options = {
    headers: {
      'token': `${authToken}`
    }
  };  

  return axios.post(`https://ekinerja.rembangkab.go.id/api/api/v1/auth/users/saveTokenFirebase`,{payload} , JSON.stringify(options));
}

export function checkVersion() {
  return axios.get(`https://ekinerja.rembangkab.go.id/api/api/v1/app/check-version`);
}

export function checkInfo(value) {
  return axios.get(`https://ekinerja.rembangkab.go.id/api/api/v1/app/information/`,{
    params: {
      status: value
    }
  });
}


