import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// import SVG from "react-inlinesvg";
import { useSubheader } from "../../_metronic/layout";
import { useGlobal, setGlobal } from "reactn";
import { Needlogin } from "../GlobalState"

import { checkVersion, checkInfo } from "../modules/Auth/_redux/authCrud";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import axios from "axios";

let aku = Needlogin

var moment = require('moment');
const var_bulan={
  nilai:moment().add(0, 'months').format('YYYY-MM')
}

export const OperatorPage = () => {
  const { user } = useSelector(state => state.auth);

  const {token} = useSelector(state => state.auth);

  const options = {
    headers: {
      'token': `${token}`
    }
  };

  const [opd] = useGlobal("ses_User")
  const [op, setOp] = useGlobal("ses_OPD")
  const [op2, setOp2] = useState(op)
  const [totalJabKosongNumber, setTotalJabKosongNumber] = useState(0)

  const [modalInfo, setModalInfo] = useState(false);
  const [info, setInfo] = useState({
    "_id": "",
    "updated_at": "",
    "information": "",
    "title": "Informasi",
    "publish": true
  });

  let step = 1
  
  useEffect(() => {
    console.log(step)
    step = 2;
    
    checkInfo(2)
      .then((result) => {
        if (result.status === 200) {
          setInfo(result.data.data)
        }
      })
    if(user.role_group == "operator") {
      axios.get(`https://ekinerja.rembangkab.go.id/api/api/v1/pegawai/jab-struktural-opd/${var_bulan.nilai}/${user.opd_id}`, options)
        .then(res => {
          // setPegawaiStrukturalOPD(res.data.data);
          // console.log("load", res.data.data)
          setTotalJabKosongNumber(res.data.totalJabKosong)
          
        })
        .catch(err => {
            err.message  = "Can't Show Jabatan Struktural";
      });
    }
    
  }, [opd, step])

  const suhbeader = useSubheader();
  suhbeader.setTitle("Dashboard Operator");



  // console.log("-- log", user);
  // console.log("-- total x", totalJabKosongNumber);

  function createMarkup() {
    return { __html: info.information };
  }

  return (
    // <>Selamat Datang Operator</>
    <>
      <div className="row">
        <div className="col-xl-6">
          <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b bg-primary"
            style={{ height: 100, backgroundImage: 'url(/media/svg/patterns/rhone.svg)' }}>
            <div className="pt-0 pb-2 pl-4 pr-2 card-body d-flex">
              <div className="d-flex py-5 flex-column align-items-start flex-grow-1">
                <div className="flex-grow-1">
                  <span href="#" className="text-white font-weight-bolder d-block font-size-h3">Selamat Datang Operator</span>
                  <span href="#" className="text-white font-weight-bolder font-size-h6">{user.opd_nama}</span>
                  <p className="text-white opacity-75 font-weight-bold mt-3">di eKinerja Kab Rembang</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="col-xl-6">
          <div className="card card-custom bgi-no-repeat gutter-b" style={{ height: 100, backgroundColor: '#663259', backgroundPosition: 'calc(100% + 0.5rem) 100%', backgroundSize: '100% auto', backgroundImage: 'url(/media/svg/patterns/taieri.svg)' }}>
            <div className="card-body d-flex align-items-center">
              <div>
                <h3 className="text-white font-weight-bolder line-height-lg mb-5">Manual eKinerja</h3>
                <a href="http://sim.rembangkab.go.id/ManualEkinerja.pdf" className="btn btn-sm btn-light-warning font-weight-bold px-6 py-3">Klik Disini</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">

        <div className="col-xl-12">
          <div className={`card card-custom`}>
                {/* Header */}
                <div className="card-header bg-danger border-0 pt-1 pl-4">
                  <h3 className="card-title font-weight-bolder text-white ">
                    {info.title}
                  </h3>
                </div>
                {/* Body */}
                <div className="card-body pt-2">
                  <div dangerouslySetInnerHTML={createMarkup()} />

                  {/* Jabatan Kosong */}
                  <div className="mr-1">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45 symbol-light mr-5">
                      <span className="symbol-label bg-danger">
                        <SVG
                          className="h-50 align-self-center text-white"
                          src={toAbsoluteUrl("/media/svg/icons/General/Fire.svg")}
                        ></SVG>
                      </span>
                    </div>

                    <div className="d-flex flex-column flex-grow-1">
                      <a
                        href="#"
                        className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                      >
                        Jabatan Struktural yang Belum Ditentukan (Kosong)
                      </a>
                      <span className="text-muted font-weight-bold">{totalJabKosongNumber} Jabatan Pegawai</span>
                    </div>
                  </div>
                </div>
                </div>
          </div>
        </div>
        
        
          
      </div>
    </>
  );
};
