var moment = require('moment'); // require
export const CustomerStatusCssClasses = ["warning","info", "success", "danger", "danger", "danger", ""];
export const CustomerStatusTitles = ["Draft","Terkirim", "Disetujui", "Ditolak", "Kadaluarsa" , "Ditolak Sistem" ,""];
export const CustomerTypeCssClasses = ["success", "primary", ""];
export const CustomerTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
  { text: "1000", value: 1000 },
];
export const initialFilter = {
  filter: {
    // tanggal: "",
    activity: "",
    waktu_usul: "",
    bulan: moment().add(0, 'months').format('YYYY-MM'),
    waktu_approval: ""
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};

export const semua = {
  filter: {
    // tanggal: "",
    activity: "",
    waktu_usul: "",
    bulan: moment().add(0, 'months').format('YYYY-MM'),
    waktu_approval: ""
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 1000
};