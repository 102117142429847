
import { useGlobal, setGlobal,resetGlobal } from "reactn";
// import React, { Suspense, lazy } from "react";
// import { Redirect, Switch, Route } from "react-router-dom";
// import axios from "axios";
import { useSelector } from "react-redux";
import { login } from "./modules/Auth/_redux/authCrud";
import axios from "axios";
var moment = require('moment'); // require
const jwt = require('jsonwebtoken')

require('dotenv').config()


const B_URL = process.env.REACT_APP_API;
// const cc= process.env.REACT_APP_STORAGE +"_jam"
const cc= process.env.REACT_APP_STORAGE +"_periode"
const dd =localStorage.getItem(process.env.REACT_APP_STORAGE)

let authToken=""
let authUser={}

 
export function getJam(){
    const options = {
        headers: {
          'token': `${authToken}`
        }
      };
    const URL= B_URL +  "/api/v1/presensi/presensijadwaltoday"
    axios.post(URL,'', options)
    .then(res => {
      let  m=(res.data.data);
        // console.log("mmmm",m)
        localStorage.setItem(cc,JSON.stringify("2021-12"));
      

    })
    .catch(err => {
        console.log("Can't send activiy");
    });

}
export function cekJam() {
  // console.log("00",authUser)
  if (!dd){
    // console.log("dd gak ada")
    localStorage.removeItem(cc);
    authToken =  null  ;
    authUser =  null ;
    }else{
        let localData = JSON.parse(dd);
        if (!localData['token']){ localStorage.removeItem(cc)}
        authToken = localData['token']? JSON.parse(localData['token']):null ;
        authUser =  localData['user']?JSON.parse(localData['user']) :null;
    }
    
    if (authToken != null && authUser.role_group =='pegawai'){
            if (!localStorage.getItem(cc)){
                getJam()
                       
            }else{
                let info=JSON.parse(localStorage.getItem(cc))
                // console.log("cek 2" + ( moment().format('YYYY-MM-DD  HH:mm:ss')) )
                // console.log("cek 3" + (info.nip != authUser.nip ) )
                if (info.hari != moment().format('YYYY-MM-DD') || info.nip != authUser.nip ){
                    getJam()
                }
            }
        
    }
}


export function Needlogin() {
    const { token } = useSelector(state => state.auth);
    // const options = { headers: { 'token': `${token}` } };
    // const storedData = localStorage.getItem('persist:eKinerjaLocal');
    const [sesOpdNama, setOpdNama] = useGlobal('ses_OPD')
    const [sesUser, setUser] = useGlobal('ses_User')
    let key = process.env.REACT_APP_NOT_JWTKEY
    if (token == undefined) { return true }
    let decoded = {}
    let opd_nama = ""
    let validtoken = {}

    try {
        decoded = jwt.verify(token, key.trim());
        setUser(decoded)
    } catch (err) {
        resetGlobal()
        return true
    }
    if (parseInt(decoded._ver) != 21) { return true }

    login(decoded.nip, decoded.password)
        .then((response) => {
            // console.log("-<>-", response)
            if (response.data.status != 200) {
                return true
            } else {
                opd_nama = response.data.opd_nama
                validtoken = response.data.token

            }

        })

    setUser(jwt.verify(token, key.trim()))
    setOpdNama(opd_nama)
    // console.log(sesUser)
    // console.log(opd_nama)
    return false
}

function initSession(options) {
    let var_bulanlist = []
    for (let i = 0; i >= -3; i--) {
        var_bulanlist.push({ nilai: moment().add(i, 'months').format('YYYY-MM'), label: moment().add(i, 'months').format('MMM-YYYY') })
    }
    setGlobal({ sess_bulan: var_bulanlist });
    let var_tppmode = [
        { mode: 100, uraian: "Kinerja 100% Individu" },
        { mode: 60, uraian: "Kinerja 60% Individu 40% Rata-Rata bawahan" },
        { mode: 40, uraian: "Kinerja 40% Individu 60% Rata-Rata bawahan" },
        { mode: 50, uraian: "Kinerja 50% ASN 50% Rata-Rata Bawahan" },
        { mode: 0, uraian: "Kinerja 100% Rata-Rata Bawahan" }
    ]
    setGlobal({ sess_modeTPP: var_bulanlist });
    return true
}