import React from "react";
import {Dashboard} from "../../_metronic/_partials";
import {useSelector ,shallowEqual} from "react-redux";
import {OperatorPage} from "./OperatorPage"

export function DashboardPage() {
  const {user} = useSelector(state => state.auth);
 
//   const {profiletoken, profileuser} = useSelector(
//     ({auth}) => ({
//         profiletoken :auth.token,
//         profileuser :auth.user,
//     }),
//     shallowEqual
// );
// console.log("test-auth-dash",profiletoken,profileuser)  
  
  let queryParams={}
  queryParams.role_group=user.role_group;
  queryParams.opd_operator=user.opd_id;
  if(user.nip === undefined ){return <OperatorPage /> }
  return <Dashboard />;
}
