import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
     // Project Settings => Add Firebase to your web app
     // messagingSenderId: "1234567890",
     apiKey: "AIzaSyC-FzrR_0w47g8Io3Edgz_1TiscztoZ64s",
     authDomain: "ekinerja-rembangkab-go-id.firebaseapp.com",
     databaseURL: "https://ekinerja-rembangkab-go-id.firebaseio.com",
     projectId: "ekinerja-rembangkab-go-id",
     storageBucket: "ekinerja-rembangkab-go-id.appspot.com",
     messagingSenderId: "655700303137",
     appId: "1:655700303137:web:8b5c223dd57b54f4a87260",
     measurementId: "G-K543JMS805"
});
const messaging = firebase.messaging.isSupported() ? initializedFirebaseApp.messaging() : null;
export { messaging };