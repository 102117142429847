/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../dropdowns";
// import {useHtmlClassService} from "../../../layout";
import axios from 'axios';
import {useSelector} from "react-redux";
import { isExpired } from "react-jwt";
// a
const moment = require('moment')
moment.locale('ID')

export function ListsWidget9({ className }) {
  const {token} = useSelector(state => state.auth);
  const [listAktv, setListAktv] = useState([]);
 // const [bulan, setBulan] = useState(moment().format("YYYY-MM"));
 
  function getWarnaIcon(kode){
    let text = kode === 0 ? 'warning' : kode === 1 ? 'primary' : kode === 2 ? 'success' : kode === 3 ? 'danger' : 'muted'
    return text;
  } 
  function getWarnaText(kode){
    let text = kode === 0 ? 'muted' : kode === 1 ? 'dark-75' : kode === 2 ? 'dark-75' : kode === 3 ? 'danger' : 'muted'
    return text;
  } 

  // const tokenSayaExpired = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmNzFkM2E5MjQzYTAwMDBhZTAwNmU1YyIsIm5pcCI6IjE5NzcwOTIyMTk5NzAzMTAwMyIsImlhdCI6MTYwNDMyMTk5MCwiZXhwIjoxNjA2OTEzOTkwLCJhdWQiOiJVc2Vyc0lkZW50aXR5Iiwic3ViIjoic2Vzc2lvbkp3dFNhdmUifQ.GfkKFYhOg9vka9Iv77iXTdr9n_EXtcrJ9Ww3IpbL9d4";

  useEffect(() => {
    let resultToken = isExpired(token);

    if(resultToken === false) {
      load();
    } else {
      console.log("** tanpa load axios 1")
    }

  }, []);

  function load(){
    let optionsResume = {
      headers: {
        'token': `${token}`
      }
    };
    let hasil=[];

    axios.get(`https://ekinerja.rembangkab.go.id/api/api/v1/dashboard/listActivity`,optionsResume).then(res => {
        hasil=(res.data.entities);
        setListAktv(hasil);
      
      }).catch(err => {
       
    });
  }

  return (
    <>
      <div className="col-lg-6 col-xxl-6 order-3"> 
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0 bg-info">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-white">List Laporan Kinerja</span>
            <span className="text-white mt-3 font-weight-bold font-size-sm">
              Laporan Terkini tanggal :
            </span>
          </h3>
          
        </div>
        {/* Body */}
        <div className="card-body pt-4">
          <div className="timeline timeline-6 mt-3">
          {listAktv.map((object, i) => (
                 <div  key={listAktv[i].id}  className="timeline-item align-items-start">
                 <div className="timeline-label font-weight-bolder text-dark-75 font-size-sm">
                 {listAktv[i].tanggal}
                 </div>
   
                 <div className="timeline-badge">
                  <i className={`fa fa-genderless icon-xl text-${getWarnaIcon(listAktv[i].status)}`}></i>
                 </div>
   
                 <div className={`timeline-content pl-3 text-${getWarnaText(listAktv[i].status)}`}>
                 <span className="d-block"><small>{listAktv[i].activity}</small></span>
                 <span className={`fa fa-envelope-open-text mr-2  text-${getWarnaIcon(listAktv[i].status)}`}> </span>
                 <span className={`pl-1 mr-2  text-${getWarnaIcon(listAktv[i].status)}`}><small>{listAktv[i].waktu_usul} menit</small></span>
                 <span className={`pl-2 mr-5  text-${getWarnaIcon(listAktv[i].status)}`}><small>{listAktv[i].status_text}</small></span>
                
                 </div>
                
               </div>
   
            ))}

          </div>
        </div>
      </div>
      </div>
      
    </>
  );
}
