/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { Nav, Accordion, Button, Card, Tab } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../../layout";

// import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { useSelector } from "react-redux";
import { DataMixedWidget1, mockDP, getChartOptionsDashEnam, GetResume, numberFormat } from "./MixedWidget1_data";
import { setGlobal, useGlobal } from 'reactn';
import { isExpired } from "react-jwt";
import { SapaKinerja } from "./SapaKinerja";
import { Responsive } from "responsive-react";

import { ListsWidget3 } from "../lists/ListsWidget3";
import { ListsWidget4 } from "../lists/ListsWidget4";
import { ListsWidget8 } from "../lists/ListsWidget8";
import { ListsWidget9 } from "../lists/ListsWidget9";
import { ListsWidget10 } from "../lists/ListsWidget10";
import { ListsWidget11 } from "../lists/ListsWidget11";
import { ListsWidget14 } from "../lists/ListsWidget14";

require('dotenv').config()



//const {DataMixedWidget1} = require('./MixedWidget1_data')

const moment = require('moment')
moment.locale('ID')

export function MixedWidget1({ className }) {
  const [key, setKey] = useState("BulanIni");
  const [resumeProfileRaw, setResumeProfileRaw] = useState(DataMixedWidget1()[0]);
  const [resumeProfile, setResumeProfile] = useState(GetResume(DataMixedWidget1()[0]));
  const [resumeSapaKinerja, setSapaKinerja] = useState([]);
  const [pd, setPD] = useState(mockDP()[0]);

  const uiService = useHtmlClassService();
  const { token } = useSelector(state => state.auth);
  const [bulan, setBulan] = useState(moment().format("YYYY-MM"));
  const [sess_bulan] = useGlobal('sess_bulan');

  // Aktivitas
  const [listAktv1, setListAktv1] = useState([]);

  if (!sess_bulan) {
    setGlobal({ sess_bulan: (moment().format("YYYY-MM")) })
  }

  // const tokenSayaExpired = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmNzFkM2E5MjQzYTAwMDBhZTAwNmU1YyIsIm5pcCI6IjE5NzcwOTIyMTk5NzAzMTAwMyIsImlhdCI6MTYwNDMyMTk5MCwiZXhwIjoxNjA2OTEzOTkwLCJhdWQiOiJVc2Vyc0lkZW50aXR5Iiwic3ViIjoic2Vzc2lvbkp3dFNhdmUifQ.GfkKFYhOg9vka9Iv77iXTdr9n_EXtcrJ9Ww3IpbL9d4";

  useEffect(() => {
    let resultToken = isExpired(token);
    if (resultToken === false) {
      load();
    } else {
      // console.log("** tanpa load axios 3")
    }

    // console.log("bulan aktif: ", bulan, "bulan_sess",sess_bulan)
  }, [bulan]);

  function load() {
    let bodydata = JSON.stringify({ "filterBulan": "2020-10-01" });
    let optionsResume = { headers: { 'filterBulan': bulan, 'token': `${token}` }, data: bodydata };
    let hasil = [];
    // axios.post(`https://ekinerja.rembangkab.go.id/api/api/v1/dashboard/comulativejabatan`, '', optionsResume).then(res => {
    // axios.post(`https://ekinerja.rembangkab.go.id/api/api/v1/reports/pegawai/resumeOPDActivity`, '', optionsResume).then(res => {
    axios.post(`https://ekinerja.rembangkab.go.id/api/api/v1/reports/pegawai/resumeKinerjaNIP`, '', optionsResume).then(res => {
      hasil = (res.data.dashboard[0]);
      // console.log("--coum hasilNIP", hasil)
      setPD(hasil)
      setSapaKinerja(res.data.sapakinerja)
      setResumeProfile(hasil.profile)

    }).catch(err => {

      // console.log("--coum error ", err)
    });

    let hasildua = [];
    axios.get(`https://ekinerja.rembangkab.go.id/api/api/v1/dashboard/resumeActivity`, optionsResume).then(res => {
      hasildua = (res.data);
      setListAktv1(hasildua);

    }).catch(err => {

    });
  }

  // }, [layoutProps]);
  // console.log("test minggu", moment("2020-10-25").format('E'))

  // function updateSkor(hasil) {
  //   GenerateTabelEnam(hasil);
  //   // GenerateDashSatu(hasil);
  // }

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
      colorsGrayGray200: objectPath.get(uiService.config, "js.colors.gray.gray200"),
      colorsGrayGray300: objectPath.get(uiService.config, "js.colors.gray.gray300"),
      colorsGrayGray500: objectPath.get(uiService.config, "js.colors.gray.gray500"),
      colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
      colorsThemeBaseDanger: objectPath.get(uiService.config, "js.colors.theme.base.danger"),
      colorsThemeBaseWarning: objectPath.get(uiService.config, "js.colors.theme.base.warning"),
      colorsThemeBaseSuccess: objectPath.get(uiService.config, "js.colors.theme.base.success"),
      colorsThemeLightSuccess: objectPath.get(uiService.config, "js.colors.theme.light.success"),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  function Lihatatasan() {
    if (pd.jabatan === undefined) { return <> <span className="text-success d-block pl-4">Nama :</span></> }

    if (pd.jabatan.atasan === undefined) { return <> <span className="text-success d-block pl-4">Nama 1:</span></> }

    let j = pd.jabatan.atasan

    return (
      <>
        {j.map((object, i) => (
          <span key={j[i].nip} >
            <span className="text-success d-block pl-4">Nama :  j[i].nama</span>
            <span className="text-dark-75 d-block pl-4">NIP  :  j[i].nip</span>
          </span>
        ))}
      </>
    )

  }
  function Jabatan() {
    if (pd.jabatan === undefined) { return <></> }
    //console.log (resumeProfileRaw.jabatan)
    let j = pd.jabatan
    // j.map((object, i) => (
    //   console.log ( j[i].atasan)
    // ))
    return (
      <>
        <span className="text-dark-75 text-hover-primary font-weight-bold d-block font-size-h6 mb-1">Nama : {pd.profile[0].nama}</span>
        <span className="text-dark-75 text-hover-primary font-weight-bold d-block font-size-sm mb-1">Jumlah Jabatan : {j.length}</span>
        {j.map((i) => (
          <span key={i.jab_kode} >
            <span className="text-dark-75 d-block">Nama Jabatan</span>
            <span className="text-info d-block pl-4 font-weight-bold">{i.jab_nama}</span>
            <span className="text-dark-75 d-block">Atasan Langsung</span>
            {

              i.atasan.map((a) => (

                <span key={a.nip} >
                  <span className="text-success d-block pl-4">Nama :  {a.nama}</span>
                  <span className="text-dark-75 d-block pl-4">NIP  :  {a.nip}</span>
                </span>

              ))
            }

          </span>

        ))}

      </>
    )
  }
  // function GenerateTabelEnam(hasil) {
  //   let chartInput = [];
  //   let chartVal = [];
  //   let chartCategory = [];
  //   let yAxis = 100;
  //   let jml = hasil.length - 1;
  //   for (let i = 0; i <= jml; i++) {
  //     chartVal.push(parseInt(hasil[i][2]));
  //     chartCategory.push(hasil[i][1]);
  //     if (parseInt(hasil[i][2]) > yAxis) { yAxis = hasil[i][2] }
  //   }
  //   chartInput.push(chartVal);
  //   chartInput.push(chartCategory);
  //   const element = document.getElementById("kt_mixed_widget_1_chart");
  //   if (!element) {
  //     return;
  //   } else {
  //     while (element.hasChildNodes()) {
  //       element.removeChild(element.firstChild);
  //     }
  //   }
  //   const options = getChartOptionsDashEnam(layoutProps, chartInput, yAxis);
  //   const chart = new ApexCharts(element, options);
  //   chart.render();
  //   return function cleanUp() {
  //     chart.destroy();
  //   };

  // }
  // dash enam end
  function handlerbulanlalu() {
    setBulan((moment().add(-1, 'months').format('YYYY-MM')))
  }

  function handlerbulanini() {
    setBulan((moment().add(0, 'months').format('YYYY-MM')))
  }

  function formatBulan() {
    if (bulan == (moment().add(0, 'months').format('YYYY-MM'))) {
      return (
        <>
          <a onClick={handlerbulanlalu} className="mr-2 font-weight-bolder font-size-sm mr-3 text-white">{moment().add(-1, 'months').format('MMMM-YYYY')}</a>
          <a className="btn btn-primary font-weight-bolder font-size-sm mr-3">{moment().add(0, 'months').format('MMMM-YYYY')}</a>
        </>
      )
    } else {
      return (
        <>
          <a className="btn btn-warning mr-2 font-weight-bolder font-size-sm">{moment().add(-1, 'months').format('MMMM-YYYY')}</a>
          <a onClick={handlerbulanini} className=" font-weight-bolder font-size-sm">{moment().add(0, 'months').format('MMMM-YYYY')}</a>
        </>
      )
    }
  }

  
  if (pd == {}) { return (<></>); }
  if (typeof resumeProfile == undefined) { return (<></>); }
  if (typeof resumeProfile.resume == undefined) { return (<></>); }

  let fotoProfile = "https://simpeg.rembangkab.go.id/simpeg.v2/modul/select/gambar.php?nip=" + pd.profile[0].nip;
 {/* LINE KINERJA START */}
{/* <ListsWidget3 />
  <ListsWidget4 />
  <ListsWidget8 />
  <ListsWidget9 />
  <ListsWidget10 />
  <ListsWidget11 />
  <ListsWidget14 /> */}
  return (
    <>
      <Responsive displayIn={["Mobile"]}>
      <div className="col-sm-12 col-lg-6 col-xl-6 col-xxl-6 pb-4">
        <SapaKinerja className="" data={resumeSapaKinerja} />
      </div>
      </Responsive>     
      <div className="col-sm-12 col-lg-6 col-xl-6 col-xxl-6">
        <div className={`card card-custom bg-light ${className}`}>
          {/* Header */}

          <div className="card-header border-0 bg-danger py-5">
            <h3 className="card-title font-weight-bolder text-white">Statistik</h3>
            <div className="card-toolbar">
              <Tab.Container defaultActiveKey={key}>
                {formatBulan()}
              </Tab.Container>
            </div>
          </div>
          {/* Body */}
          <div className="card-body p-0 position-relative overflow-hidden">
            {/* Chart */}
            <div id="kt_mixed_widget_1_chart" className="card-rounded-bottom bg-danger pl-2" style={{ height: "120px" }} >

            </div>

            {/* Stat */}
            <div className="card-spacer mt-n40">
              <div className="row">

                <div className="col-4 symbol symbol-60 symbol-xxl-100  p-1 mx-auto bg-white">
                  <div className="symbol-label m-0 p-0" style={{ height: "120px", width: "auto", backgroundImage: `url(${toAbsoluteUrl(fotoProfile)})` }}>

                  </div>
                  <i className="symbol-badge bg-success"></i>

                </div>

                <div className="col-8 align-items-baseline pt-4 pb-4">
                  <div className="mx-auto mt-6 d-block">
                    <a href="#" className="font-weight-bolder font-size-h5 text-white text-hover-white" >
                      {pd.profile[0].nama}
                    </a>
                    <div className="text-dark">NIP : {pd.profile[0].nip}</div>
                  </div>
                </div>
              </div>

              <div className="ml-n6 mr-n6 mt-1">
                <table className="table table-borderless mb-0 ">
                  <tbody>
                    <tr className="" style={{ height: "35px" }} >
                      <td /><td /><td /><td /></tr>
                    <tr className="bg-info">
                      <td className="w-40px pl-0 pr-2">
                        <div className="symbol symbol-40 symbol-light-info align-middle  pl-2">
                          <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-info">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")} ></SVG>
                            </span>
                          </span>
                        </div>
                      </td>
                      <td colSpan={2} className="font-size-lg font-weight-bolder text-white w-150px align-middle">TOTAL BASE TPP</td>
                      {/* <td className="font-weight-bold text-white text-right align-middle"></td> */}
                      <td className="font-weight-bolder font-size-lg text-white w-100px text-right align-middle">{numberFormat(pd.profile[0].jmlh_tpp)}</td>
                    </tr>
                    <tr className="bg-danger">
                      <td className="w-40px pl-0 pr-2">
                        <div className="symbol symbol-40 symbol-light-info align-middle  pl-2">
                          <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-info">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}></SVG>
                            </span>
                          </span>
                        </div>
                      </td>
                      <td className="font-size-lg font-weight-bolder text-white w-150px align-middle">Capaian Kinerja</td>
                      <td className="font-weight-bold text-white text-right align-middle">

                        {numberFormat((pd.reportFinal[0].capaian_prosen_total).toFixed(2))} % x {pd.reportFinal[0].prosentase}%

                      </td>
                      <td className="font-weight-bolder font-size-lg text-white w-100px text-right align-middle">{numberFormat((pd.reportFinal[0].tpp_kinerja_final).toFixed(0))} </td>
                    </tr>
                    <tr className="bg-warning">
                      <td className="w-40px pl-0 pr-2">
                        <div className="symbol symbol-40 symbol-light-warning align-middle  pl-2">
                          <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-warning">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}></SVG>
                            </span>
                          </span>
                        </div>
                      </td>
                      <td className="font-size-lg font-weight-bolder text-white w-150px align-middle">Capaian Presensi</td>
                      <td className="font-weight-bold text-white text-right align-middle">
                        {pd.presensi[0].presensi_persenTotal}% x {100 - pd.reportFinal[0].prosentase}%
                      </td>
                      <td className="font-weight-bolder font-size-lg text-white text-right align-middle">
                        {numberFormat((pd.reportFinal[0].tpp_presensi).toFixed(0))}
                      </td>
                    </tr>
                    <tr className="bg-success">
                      <td className="w-40px pl-0 pr-2">
                        <div className="symbol symbol-40 symbol-light-success align-middle pl-2">
                          <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-success">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group-chat.svg")}></SVG>
                            </span>
                          </span>
                        </div>
                      </td>
                      <td className="font-size-lg font-weight-bolder text-white w-150px align-middle">Capaian Total</td>
                      <td className="font-weight-bold text-white text-right align-middle">TPP yg diterima</td>
                      <td className="font-weight-bolder font-size-lg text-white text-right align-middle">
                        {numberFormat((pd.reportFinal[0].tpp_plt_total).toFixed(0))}</td>
                    </tr>
                  </tbody>
                </table>

                <table className="table table-borderless mt-10">
                  <tbody>
                    <tr className="bg-light-warning">
                      <td className="w-40px pl-0 pr-2">
                        <div className="symbol symbol-40 symbol-light-warning align-middle  pl-2">
                          <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-warning">
                              <SVG className="h-75 align-self-end" src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                            </span>
                          </span>
                        </div>
                      </td>
                      <td className="w-100 font-size-lg font-weight-bolder text-dark-75 lign-left">Usulan Kinerja Draft<br />
                        <span className="font-weight-bold text-muted text-right align-middle">Draft : {listAktv1.draft} usulan ({listAktv1.waktu_draft}) menit</span>
                      </td>
                    </tr>
                    <tr className="bg-light-info">
                      <td className="w-40px pl-0 pr-2">
                        <div className="symbol symbol-40 symbol-light-info align-middle  pl-2">
                          <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-info">
                              <SVG className="h-75 align-self-end" src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} ></SVG>
                            </span>
                          </span>
                        </div>
                      </td>
                      <td className="font-size-lg font-weight-bolder text-dark-75 w-150px align-left">Usulan Kinerja Terkirim<br />
                        <span className="font-weight-bold text-muted text-right align-middle">Terkirim : {listAktv1.terkirim} usulan ({listAktv1.waktu_terkirim}) menit</span>
                      </td>

                    </tr>
                    <tr className="bg-light-success">
                      <td className="w-40px pl-0 pr-2">
                        <div className="symbol symbol-40 symbol-light-success align-middle  pl-2">
                          <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-success">
                              <SVG className="h-75 align-self-end" src={toAbsoluteUrl("/media/svg/icons/Communication/Group-chat.svg")}></SVG>
                            </span>
                          </span>
                        </div>
                      </td>
                      <td className="font-size-lg font-weight-bolder text-dark-75 w-150px align-left">Usulan Kinerja Disetujui<br />
                        <span className="font-weight-bold text-muted text-right align-middle">Disetujui : {listAktv1.setujui} usulan ({listAktv1.waktu_setujui}) menit </span>
                      </td>

                    </tr>
                    <tr className="bg-light-danger">
                      <td className="w-40px pl-0 pr-2">
                        <div className="symbol symbol-40 symbol-light-danger align-middle pl-2">
                          <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-danger">
                              <SVG className="h-75 align-self-end" src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}></SVG>
                            </span>
                          </span>
                        </div>
                      </td>
                      <td className="font-size-lg font-weight-bolder text-dark-75 w-150px align-left">Usulan Kinerja Ditolak<br />
                        <span className="font-weight-bold text-muted text-right align-middle">Ditolak : {listAktv1.tolak} usulan ({listAktv1.waktu_tolak}) menit </span>
                      </td>

                    </tr>
                  </tbody>
                </table>

              </div>
              {/* end div ent table responsive */}

            </div>

            {/* Resize */}
            <div className="resize-triggers">
              <div className="expand-trigger">
                <div style={{ width: "411px", height: "461px" }} />
              </div>
              <div className="contract-trigger" />
            </div>
          </div>
        </div>

      </div>
      {/* LINE KET START */}

      <div className="col-sm-12 col-lg-6 col-xl-6 col-xxl-6">
        {/*begin::List Widget 19*/}
        <div className="card card-custom card-stretch gutter-b">
          {/*begin::Header*/}
          <div className="card-header bg-success  border-0 pt-6 mb-2">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bold font-size-h4 text-white mb-3"> RESUME KINERJA {bulan}</span>
              <span className="text-white font-weight-bold font-size-sm">Rincian Perhitungan Kinerja</span>
              <br />
            </h3>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body pt-2">
            {/*begin::Table*/}
            <div className="table-responsive">

              <table className="table table-borderless mb-0">
                <tbody>
                  {/*begin::Item*/}
                  <tr>
                    <td className="w-40px align-top pb-6 pl-0 pr-2">
                      {/*begin::Symbol*/}
                      <div className="symbol symbol-40 symbol-light-success">
                        <span className="symbol-label">
                          <span className="svg-icon svg-icon-lg svg-icon-success">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart3.svg")}></SVG></span>
                        </span>
                      </div>
                      {/*end::Symbol*/}
                    </td>
                    <td colSpan="3" className="font-size-lg font-weight-bolder text-dark-75 align-top  pb-6">
                      {Jabatan()}
                    </td>

                    {/* <td className="font-weight-bolder font-size-lg text-dark-75 text-right align-middle pb-6">5.4MB</td> */}
                  </tr>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  <tr>
                    <td className="w-40px pb-6 pl-0 pr-2">
                      {/*begin::Symbol*/}
                      <div className="symbol symbol-40 symbol-light-info align-top">
                        <span className="symbol-label">
                          <span className="svg-icon svg-icon-l svg-icon-info">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}></SVG></span>
                        </span>
                      </div>
                      {/*end::Symbol*/}
                    </td>
                    <td className="font-size-lg font-size-lg-sm font-weight-bold text-dark-75 pr-0 w-50 align-top pb-6">
                      <span className="d-block text-info bg-light-info">Laporan Kinerja:</span>
                      <span className="d-block">Individu</span>
                      <span className="d-block">Bawahan | ({pd.reportFinal[0].bawahan_jumlah}) org </span>
                      <span className="d-block text-info">Capaian Kinerja </span>

                    </td>
                    <td className="font-size-lg font-size-lg-sm font-weight-bold text-muted text-right pr-0 pl-0 align-top pb-6">
                      {/* <span className="d-block"><br/></span> */}
                      <span className="d-block text-info text-right bg-light-info">bobot</span>
                      <span className="d-block">bobot : {pd.reportFinal[0].individu_mode}%</span>
                      <span className="d-block">bobot : {pd.reportFinal[0].bawahan_mode}%</span>
                      <span className="d-block"><br /></span>

                    </td>
                    <td className="font-size-lg font-size-lg-sm font-weight-bold text-dark-75 text-right pl-0 align-top pb-6">
                      {/* <span className="d-block"><br/></span> */}
                      <span className="d-block text-info bg-light-info">{(pd.reportFinal[0].prosentase).toFixed(2)} %</span>
                      <span className="d-block">{pd.reportFinal[0].individu_prosen.toFixed(2)}%</span>
                      <span className="d-block">{pd.reportFinal[0].bawahan_prosen.toFixed(2)}%</span>
                      <span className="d-block text-info">{numberFormat((pd.reportFinal[0].capaian_prosen_total).toFixed(2))} %</span>
                    </td>
                  </tr>

                  <tr>
                    <td className="w-40px pb-6 pl-0 pr-2">
                      {/*begin::Symbol*/}
                      <div className="symbol symbol-40 symbol-light-warning align-top">
                        <span className="symbol-label">
                          <span className="svg-icon svg-icon-l svg-icon-warning">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}></SVG></span>
                        </span>
                      </div>
                      {/*end::Symbol*/}
                    </td>
                    <td className="font-size-lg font-size-lg-sm font-weight-bold text-dark-75  pr-0 align-top pb-6">
                      <span className="d-block text-warning bg-light-warning">Potongan Presensi:</span>
                      <span className="d-block">Tidak Hadir</span>
                      <span className="d-block">Akumulasi Menit </span>
                      <span className="d-block text-warning">Capaian Presensi  </span>

                    </td>
                    <td className="font-size-lg font-size-lg-sm font-weight-bold text-muted text-center  w-25 pl-0 pr-0 align-top pb-6">
                      <span className="d-block text-warning text-right bg-light-warning">bobot</span>
                      <span className="d-block">({pd.presensi[0].presensi_T}) hr</span>
                      <span className="d-block">({pd.presensi[0].presensi_A})mnt</span>
                      <span className="d-block"><br /></span>

                    </td>
                    <td className="font-size-lg font-size-lg-sm font-weight-bold text-dark-75 text-right w-25 pl-0 align-top pb-6">
                      <span className="d-block text-warning bg-light-warning">{(100 - pd.reportFinal[0].prosentase).toFixed(2)} %</span>
                      <span className="d-block">{(pd.presensi[0].presensi_persenT).toFixed(2)}%</span>
                      <span className="d-block">{(pd.presensi[0].presensi_persenA).toFixed(2)}%</span>
                      <span className="d-block text-warning">{(pd.presensi[0].presensi_persenTotal).toFixed(2)}%</span>
                    </td>
                  </tr>

                  <tr>
                    <td className="w-40px pb-6 pl-0 pr-2">
                      {/*begin::Symbol*/}
                      <div className="symbol symbol-40 symbol-light-success align-top">
                        <span className="symbol-label">
                          <span className="svg-icon svg-icon-l svg-icon-success">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}></SVG></span>
                        </span>
                      </div>
                      {/*end::Symbol*/}
                    </td>
                    <td className="font-size-lg font-size-lg-sm font-weight-bold text-dark-75  pr-0 align-top pb-6">
                      <span className="d-block text-success bg-light-success">TPP Perhitungan:</span>
                      <span className="d-block">Capaian Kinerja</span>
                      <span className="d-block">Capaian Presensi </span>
                      <span className="d-block text-success">Total Capaian  </span>
                      <span className="d-block text-success">TPP  </span>
                      {pd.reportFinal[0].tpp_plt > 0 ?
                        <>
                          <span className="d-block">TPP Tamb PLT  </span>
                          <span className="d-block">TPP Total </span>
                        </>
                        :
                        <></>
                      }

                    </td>
                    <td className="font-size-lg font-size-lg-sm font-weight-bold text-muted text-center  w-25 pl-0 pr-0 align-top pb-6">
                      <span className="d-block text-success text-right bg-light-success"><br /></span>
                      <span className="d-block">
                        {numberFormat(pd.reportFinal[0].capaian_prosen_total.toFixed(0))} % x {pd.reportFinal[0].prosentase}%
                      </span>
                      <span className="d-block">
                        {pd.presensi[0].presensi_persenTotal}% x {(100 - pd.reportFinal[0].prosentase)}%
                      </span>
                      <span className="d-block"><br /></span>

                    </td>
                    <td className="font-size-lg font-size-lg-sm font-weight-bold text-dark-75 text-right w-25 pl-0 align-top pb-6">
                      <span className="d-block text-success bg-light-success">{numberFormat(pd.reportFinal[0].tpp_basis)}</span>
                      <span className="d-block">
                        {numberFormat((pd.reportFinal[0].capaian_prosen_total * pd.reportFinal[0].prosentase / 100).toFixed(2))}%
                      </span>
                      <span className="d-block">
                        {numberFormat(
                          ((pd.presensi[0].presensi_persenTotal * (100 - pd.reportFinal[0].prosentase)) / 100).toFixed(2)

                        )}%
                      </span>
                      <span className="d-block text-success">
                        {numberFormat(((
                          (pd.reportFinal[0].capaian_prosen_total * pd.reportFinal[0].prosentase / 100)
                          +
                          (pd.presensi[0].presensi_persenTotal * (100 - pd.reportFinal[0].prosentase)) / 100)
                        ).toFixed(2))
                        }%
                      </span>
                      <span className="d-block text-success">
                        {numberFormat(((
                          (pd.reportFinal[0].capaian_prosen_total * pd.reportFinal[0].prosentase / 100)
                          +
                          (pd.presensi[0].presensi_persenTotal * (100 - pd.reportFinal[0].prosentase)) / 100)
                          / 100 * pd.reportFinal[0].tpp_basis.toFixed(2))
                        )
                        }
                      </span>
                      {pd.reportFinal[0].tpp_plt > 0 ?
                        <>
                          <span className="d-block">{numberFormat(pd.reportFinal[0].tpp_plt)} </span>
                          <span className="d-block">
                            {numberFormat((
                              (pd.reportFinal[0].capaian_prosen_total * pd.reportFinal[0].prosentase / 100)
                              +
                              (pd.presensi[0].presensi_persenTotal * (100 - pd.reportFinal[0].prosentase)) / 100)
                              / 100 * pd.reportFinal[0].tpp_basis + pd.reportFinal[0].tpp_plt
                            )}
                          </span>
                        </>
                        :
                        <></>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*end::Table*/}
          </div>
          {/*end::Body*/}
        </div>
        {/*end::List Widget 19*/}

        

      </div>
      {/* LINE KET END */}

      <Responsive displayIn={["Laptop", "Tablet"]}>
      <div className="col-sm-12 col-lg-6 col-xl-6 col-xxl-6">
        <SapaKinerja className="" data={resumeSapaKinerja} />
      </div>
      </Responsive>  

    </>
  )

}

