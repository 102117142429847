import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Offline } from "./Offline";


export default function OfflinePage() {
  return (
    <Switch>
      <Redirect from="/offline" exact={true} to="/offline/page" />
      <Route path="/offline/page" component={Offline} />
    </Switch>
  );
}
