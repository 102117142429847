/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, {useState} from "react";
import {Tab, Nav} from "react-bootstrap";
import {QuickAktivitas} from "./QuickAktivitas";
import {QuickBawahan} from "./QuickBawahan";
import {QuickIjin} from "./QuickIjin";
import {useSelector, shallowEqual} from "react-redux";

export function QuickPanel() {
  const [selectedTab, setSelectedTab] = useState("Aktivitas");
  const {user} = useSelector(state => state.auth);
  const setTab = _tabName => {
    setSelectedTab(_tabName);
  };

  

  function loadPanel(){
    if(user.role_group === "pegawai"){
      return (
        <>
          <div
              id="kt_quick_panel_logs"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${selectedTab === "Aktivitas" ? "active show" : ""}`}
          >
            <div className="mb-15">
              <h5 className="font-weight-bold mb-5">Aktivitas Anda</h5>
              <QuickAktivitas />
            </div>
          </div>
          <div
              id="kt_quick_panel_notifications"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${selectedTab === "Bawahan" ? "active show" : ""}`}
          >
            <div className="mb-15">
              <h5 className="font-weight-bold mb-5">Aktivitas Bawahan</h5>
              <QuickBawahan />
            </div>
          </div>
          <div
              id="kt_quick_panel_settings"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${selectedTab === "Ijin" ? "active show" : ""}`}
          >
            <div className="mb-15">
              <h5 className="font-weight-bold mb-5">Daftar Ijin & Cuti</h5>
              <QuickIjin />
            </div>
          </div>
        </>
      );
    }
  }

  return (
      <div id="kt_quick_panel" className="offcanvas offcanvas-right pt-5 pb-10">
        <Tab.Container
            defaultActiveKey={selectedTab}
        >
          {/*begin::Header*/}
          <div
              className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
            <Nav
                onSelect={setTab}
                as="ul"
                role="tablist"
                className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
            >
              <Nav.Item as="li">
                <Nav.Link
                    eventKey="Aktivitas"
                    className={`nav-link ${
                        selectedTab === "Aktivitas" ? "active" : ""
                    }`}
                >
                  Aktivitas
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                    eventKey="Bawahan"
                    className={`nav-link ${
                        selectedTab === "Bawahan" ? "active" : ""
                    }`}
                >
                  Bawahan
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link
                    eventKey="Ijin"
                    className={`nav-link ${
                        selectedTab === "Ijin" ? "active" : ""
                    }`}
                >
                  Ijin & Cuti
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <div className="offcanvas-close mt-n1 pr-5" style={{position: "absolute", top: "15px", right: "10px"}}>
              <a
                  href="#"
                  className="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_panel_close"
              >
                <i className="ki ki-close icon-xs text-muted"></i>
              </a>
            </div>
          </div>
          {/*end::Header*/}

          {/*begin::Content*/}
          <div className="offcanvas-content px-10">
            <div className="tab-content">
              {loadPanel()}
            </div>
          </div>
          {/*end::Content*/}
        </Tab.Container>
      </div>
  );
}
