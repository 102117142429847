/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import {useSelector} from "react-redux";
//import {checkIsActive} from "../../../../_helpers";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";


export function AsideMenuList({ layoutProps }) {
  const {user} = useSelector(state => state.auth);
  const history = useHistory();  
  
  const logoutClick = () => {
      
    history.push("/logout");
  };

  const role_group = user.role_group;

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  function operator() {
    if (role_group !== "operator") { return (<></>) }
    return (
      <>
        {/* Operator Pages */}
        {/* begin::section */}
        <li className="menu-section ">
            <h4 className="menu-text">Menu Operator</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/master", true)}`} aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/master">
            <span className="menu-icon fa fa-tools">
            </span>
            <span className="menu-text">Menu Operator OPD</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Menu Operator OPD</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/master/pegawai")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/master/pegawai">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Master Pegawai OPD</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
                {/*begin::2 Level*/}
                <li className={`menu-item ${getMenuItemActive("/master/presensi")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/master/presensi">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Rekap Presensi Pegawai</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              
              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/master/kinerja")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/master/kinerja">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Rekap Kinerja Pegawai</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

            
              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/user-profile/change-password")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/user-profile/change-password">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text text-warning">Ganti Password User</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/util/logout")}`} aria-haspopup="false">
                <a className="menu-link" onClick={logoutClick}>
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text text-warning">Keluar </span>
                </a>
              </li>
              {/*end::2 Level*/}



            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
        {/* End Operator Pages */}
      </>
    )
  }
  
  function admin() {
    if (role_group !== "admin") { return (<></>) }
   return (<>

      {/* Admin-Page */}
      {/*begin::1 Level*/}
      {/* begin::section */}
      <li className="menu-section ">
          <h4 className="menu-text">Menu Admin</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
      {/* end:: section */}
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive("/adminpemkab", true)} ${getMenuItemActive("/master", true)}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <NavLink className="menu-link menu-toggle" to="/adminpemkab">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
          </span>
          <span className="menu-text">Menu Admin Pemkab</span>
          <i className="menu-arrow" />
        </NavLink>
        <div className="menu-submenu ">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            <li className="menu-item  menu-item-parent" aria-haspopup="true">
              <span className="menu-link">
                <span className="menu-text">Master</span>
              </span>
            </li>

            {/* Master */}
            {/*begin::2 Level*/}
            <li className={`menu-item menu-item-submenu ${getMenuItemActive("/adminpemkab", true)} ${getMenuItemActive("/master", true)}`}
              aria-haspopup="true" data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/adminpemkab">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className="menu-text">Master</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  {/*begin::3 Level*/}
                  <li className={`menu-item  ${getMenuItemActive("/adminpemkab/opd")}`} aria-haspopup="true" >
                    <a className="menu-link"
                      href="/adminpemkab/opd">
                      <i className="menu-bullet menu-bullet-dot"><span /></i>
                      <span className="menu-text">OPD/Satker</span>
                    </a>
                  </li>
                  {/*end::3 Level*/}


                  {/*begin::3 Level*/}
                  <li className={`menu-item  ${getMenuItemActive("/master/pegawai")}`} aria-haspopup="true" >
                    <a className="menu-link"
                      href="/master/pegawai">
                      <i className="menu-bullet menu-bullet-dot"><span /></i>
                      <span className="menu-text">Data Pegawai</span>
                    </a>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::3 Level*/}
                  <li className={`menu-item  ${getMenuItemActive("/adminpemkab/jabatan")}`} aria-haspopup="true" >
                    <a className="menu-link" href="/adminpemkab/jabatan">
                      <i className="menu-bullet menu-bullet-dot"><span /></i>
                      <span className="menu-text">Jabatan</span>
                    </a>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::3 Level*/}
                  <li className={`menu-item  ${getMenuItemActive("/adminpemkab/tpp")}`} aria-haspopup="true" >
                    <a className="menu-link" href="/adminpemkab/tpp">
                      <i className="menu-bullet menu-bullet-dot"><span /></i>
                      <span className="menu-text">TPP</span>
                    </a>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::3 Level*/}
                  <li className={`menu-item  ${getMenuItemActive("/adminpemkab/operator")}`} aria-haspopup="true" >
                    <a className="menu-link" href="/adminpemkab/operator">
                      <i className="menu-bullet menu-bullet-dot"><span /></i>
                      <span className="menu-text">Operator</span>
                    </a>
                  </li>
                  {/*end::3 Level*/}


                </ul>
              </div>
            </li>
            {/*end::2 Level*/}


           

            {/*begin::2 Level*/}
            <li className={`menu-item ${getMenuItemActive("/master/presensi")}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/master/presensi">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className="menu-text">Rekap Presensi PerOPD</span>
              </NavLink>
            </li>
            {/*end::2 Level*/}

            {/*begin::3 Level*/}
             <li className={`menu-item ${getMenuItemActive("/master/kinerja")}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/master/kinerja">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className="menu-text">Rekap Kinerja OPD</span>
              </NavLink>
            </li>
            {/*end::3 Level*/}

            {/*begin::4 Level*/}
            <li className={`menu-item ${getMenuItemActive("/master/pegawai/log-presensi")}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/master/pegawai/log-presensi">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className="menu-text">Rekap Log ePresensi</span>
              </NavLink>
            </li>
            {/*end::4 Level*/}
            
            {/*begin::2 Level*/}
            <li className={`menu-item ${getMenuItemActive("/user-profile/change-password")}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/user-profile/change-password">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className="menu-text text-warning">Ganti Password User</span>
              </NavLink>
            </li>
            {/*end::2 Level*/}


            {/*begin::2 Level*/}
            <li className={`menu-item ${getMenuItemActive("/util/logout")}`} aria-haspopup="false">
              <a className="menu-link" onClick={logoutClick}>
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className="menu-text text-danger">Keluar </span>
              </a>
            </li>
            {/*end::2 Level*/}


          </ul>
        </div>
      </li>
      {/*end::1 Level*/}

    </>)
  }

  function pegawai() {
    // if (role_group !== "pegawai") { return (<></>) }
    if (!user.nip) { return (<></>) }
    return (
      <>
        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Aktivitas</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Aktivitas Pages */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/data", true)}`} aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/data/aktivitas">
            <span className="menu-icon fa fa-chalkboard-teacher">
            </span>
            <span className="menu-text">Aktivitas Pegawai</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Aktivitas Pegawai</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/data/aktivitas")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/data/aktivitas">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Daftar Aktifitas</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/data/reportaktivitas")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/data/reportaktivitas">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Resume Aktifitas</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}


            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Presensi Pages */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/presensi", true)}`} aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/presensi">
            <span className="menu-icon fa fa-user-clock">
            </span>
            <span className="menu-text">Data Presensi</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Data Presensi</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/presensi/ijin")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/presensi/ijin">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Daftar Ijin/Cuti</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/presensi/log")}`} aria-haspopup="false">
                <NavLink className="menu-link" to="/presensi/log">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Log Kehadiran </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}


            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
        {/* End Presensi Pages */}


        {/* Aktivitas Pages */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/penilaian", true)}`} aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/penilaian/aktivitas">
            <span className="menu-icon fa fa-users">
            </span>
            <span className="menu-text">Kinerja Bawahan</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Kinerja Bawahan</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/penilaian/aktivitas")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/penilaian/aktivitas">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Penilaian Kinerja</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/penilaian/resume")}`} aria-haspopup="false">
                <NavLink className="menu-link" to="/penilaian/resume">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Resume Kinerja </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}


            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
        {/* End Aktivitas Pages */}

        {/* Setting Pages */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/user-profile", true)}`} aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/user-profile">
            <span className="menu-icon fa fa-tools">
            </span>
            <span className="menu-text">Setting</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Setting</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/user-profile/profile-overview")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/user-profile/profile-overview">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Profil</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/user-profile/change-password")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/user-profile/change-password">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Ganti Password</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li className={`menu-item ${getMenuItemActive("/util/logout")}`} aria-haspopup="false">
                <a className="menu-link" onClick={logoutClick}>
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Logout</span>
                </a>
              </li>
              {/*end::2 Level*/}


            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
        {/* End Setting Pages */}
      </>
    );
  }


  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-icon fas fa-home">

            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {pegawai()}
        {operator()}
        {admin()}


      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
