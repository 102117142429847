import axios from "axios";
// import { useSelector } from "react-redux";

export const URL = "https://ekinerja.rembangkab.go.id/api/api/v1/pegawai";

function getOptions(){
  const storedData = localStorage.getItem('persist:eKinerjaLocal');
  let localData = JSON.parse(storedData);
  let options = {
    headers: {
      'token': ""
    }
  };
  if (localData['token']) {
    const storedData2 = localStorage.getItem('persist:eKinerjaLocal');
    let localData2 = JSON.parse(storedData2);
    let authToken = JSON.parse(localData2['token']);
    options = {
      headers: {
        'token': `${authToken}`
      }
    };
    return options
  }else{
     options = {
      headers: {
        'token': ""
      }
    };
    return options
  }
}


export function detail(nip) {
  return axios.get(`${URL}/detail/${nip}`, getOptions());
}

export function update(pegawai) {
  return axios.post(`${URL}/update`, { pegawai }, getOptions());
}

