/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState} from "react";
// import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
// import { QuickActions } from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Responsive } from "responsive-react";
import { Modal, Button } from "react-bootstrap";
import packageJson from '../../../../package.alias.json';
var moment = require('moment'); // require


export function SubHeader() {
  // const { bulanlist } = useSelector(state => state.auth);
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const hariIni = (new Date()).toLocaleDateString();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      )
    };
  }, [uiService]);

  const [openModalInstall, setOpenModalInstall] = useState(false);

  function handleClickOpen() {
    setOpenModalInstall(true);
  }

  function handleClickClose() {
    setOpenModalInstall(false);
  }

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <div className="d-flex align-items-baseline mr-5">
            <h5 className="text-dark font-weight-bold my-2 mr-5">
              <>
                {subheader.title}
              </>
              {/*<small></small>*/}
            </h5>

          </div>
          <Responsive displayIn={["Laptop"]}>
            <BreadCrumbs items={subheader.breadcrumbs} />
          </Responsive>
        </div>

        {/* Toolbar */}
        <div className="d-flex align-items-center ">
        {/* <a href="#" className="btn btn-light btn-sm font-weight-bold">
            <span className="text-muted font-weight-bold mr-4">Bulan : </span>
        </a>
        <select
                  className="form-control"
                  // size="sm"
                  name="bulan"
                  placeholder="Filter by Status"
                  // TODO: Change this code
                  // onChange={(e) => {
                  //   setFieldValue("status", e.target.value);
                  //   handleSubmit();
                  // }}
                  // onBlur={handleBlur}
                  // value={values.bulan}
                >
                  <option value="">All</option>
                  <option value="0">Draft</option>
                  <option value="1">Terkirim</option>
                  <option value="2">Disetujui</option>
                  <option value="3">Ditolak</option>
                </select> */}

          {/* <Responsive displayIn={["Mobile", "Tablet"]}> */}
            {/* <button className="btn btn-danger btn-sm font-weight-bold install-button mr-3" id="buttonInstall" onClick={handleClickOpen}>
              Install Android iOS
            </button> */}
            <Modal show={openModalInstall} onHide={handleClickClose}>
              <Modal.Header closeButton>
                <Modal.Title>Install eKinerja</Modal.Title>
              </Modal.Header>
              <Modal.Body>Apakah anda ingin menginstall eKinerja ?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClickClose}>
                  Batal
                </Button>
                <Button variant="danger" className="installnow">
                  Install
                </Button>
              </Modal.Footer>
            </Modal>
          {/* </Responsive> */}
          <a href="#" className="btn btn-light btn-sm font-weight-bold mr-2">
            <span className="text-muted font-weight-bold mr-1" id="kt_dashboard_daterangepicker_title">Versi {packageJson.version}</span>
          </a>
          <a href="#" className="btn btn-light btn-sm font-weight-bold">
            <span className="text-muted font-weight-bold mr-1" id="kt_dashboard_daterangepicker_title">Hari ini: </span>
            <span className="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">{hariIni}</span>
          </a>
          {/* <QuickActions /> */}
        </div>
      </div>
    </div>
  );
}
