import React, { Suspense } from "react";
import { ChangePassword } from './pages/ChangePassword' 
import { Switch } from "react-router-dom";
// import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";


export {AuthPage} from "./pages/AuthPage";
export {default as Logout} from "./pages/Logout";

export default function Auth() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <ContentRoute path="/util/password" component={ChangePassword} />
            </Switch>
        </Suspense>
    )
}