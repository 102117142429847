/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect, useMemo } from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_helpers";
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import * as actions from  "../../../../../app/modules/Data/_redux/aktivitas/customersActions";
// import { useCustomersUIContext } from "../../../../../app/modules/Data/pages/aktivitas/CustomersUIContext";
import { initialFilter } from "../../../../../app/modules/Data/pages/aktivitas/CustomersUIHelpers";

export function QuickAktivitas() {
const history = useHistory();
  const aktivitas = () => {
    const toggle = document.getElementById("kt_quick_panel_logs");
      if (toggle) {
        toggle.click();
      }
    history.push("/data/aktivitas");
};

  const { currentState } = useSelector(
    (state) => ({ currentState: state.customers }),
    shallowEqual
  );

  const { entities } = currentState;
  const dispatch = useDispatch();

  useEffect(() => {
   dispatch(actions.fetchCustomers(initialFilter));
  }, [initialFilter, dispatch]);
  
  if(entities === null || entities === undefined || entities[0] === undefined ){
    return <></>;
  }

  if(entities.length<1 ){
    return <></>;
  }

  return (
    <div>
    {entities.map((object, i) => (
        <div className="d-flex align-items-center flex-wrap mb-5" key={entities[i]._id}>
          <div className="symbol symbol-50 symbol-light mr-3">
            <div className="symbol symbol-50 mr-1">
              <div className="symbol-label">
                <i className="flaticon-notepad text-primary icon-lg" ></i>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <a
                // href="/data/aktivitas"
                onClick={aktivitas}
                className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1"
            >
              {entities[i].tanggal}
              <span className="btn btn-sm btn-primary font-weight-bolder py-1 my-lg-0 my-2 float-right">
                {entities[i].status_text}
              </span>
            </a>
            <span className="text-muted font-weight-bold">
              {entities[i].activity.substr(0,18)}...
              <span className="float-right">{entities[i].waktu_usul} menit</span>
            </span>
          </div>          
        </div>
      ))}
    </div>
  );
}
