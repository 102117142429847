/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { format } from 'date-fns';
import { id } from 'date-fns/locale'; // Import locale for Indonesian

export function SapaKinerja({ className, data }) {

  // Ensure data is an object and has the expected fields
  if (!data || typeof data !== 'object') {
    return <p>Loading...</p>; // Or a different loading indicator
  }

  // Extract specific fields
  const {
    rata_immediate,
    rata_inter,
    rata_kinerja,
    rata_ultimate,
    total_rata,
  } = data;

  // Define background colors and icons for different data fields
  const items = [
    { label: 'Rata Rata Kinerja', value: rata_kinerja, bgClass: 'bg-primary' },
    { label: 'Rata Rata Outcome Ultimate', value: rata_ultimate, bgClass: 'bg-danger' },
    { label: 'Rata Rata Outcome Intermediate', value: rata_inter, bgClass: 'bg-warning' },
    { label: 'Rata Rata Outcome Immediate', value: rata_immediate, bgClass: 'bg-success' },
    { label: 'Total Rata Rata', value: total_rata, bgClass: 'bg-dark' },
  ];

  // Current date and time
  const now = new Date();
  const formattedDate = format(now, 'dd MMMM yyyy, HH:mm:ss', { locale: id });

  return (
    <>
      {/* begin::List Widget 14 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header bg-success border-0 pt-6 mb-2">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bold font-size-h4 text-white mb-3">Statistik SAPA KINERJA</span>
            <span className="text-white font-weight-bold font-size-sm">Data tersinkron per tanggal {formattedDate}</span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-3">
          <div className="row">
            {items.map((item, index) => (
              <div key={index} className="col-lg-12 col-md-12 col-sm-12 mb-3">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <span className="svg-icon svg-icon-lg mr-3">
                      <SVG className="h-75 align-self-end" src={toAbsoluteUrl("/media/svg/icons/Communication/Group-chat.svg")}></SVG>
                    </span>
                    <span className="font-weight-bold text-dark flex-grow-1">
                      {item.label}
                    </span>
                  </div>
                  <div className="progress">
                    <div
                      className={`progress-bar ${item.bgClass}`}
                      role="progressbar"
                      style={{ width: `${item.value}%` }}
                      aria-valuenow={item.value}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {item.value}%
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::List Widget 14 */}
    </>
  );
}
