import React from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function InputGroup({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  setFieldValue,
  ...props
}) {

return (
    
    <>
      {label && <label>{label}</label>}
      <div className="input-group">
        <input
          type={type}
          className={getFieldCSSClasses(touched[field.name], errors[field.name])}
          {...field}
          value={(field.value) || ''}
        />
        <div className="input-group-append"><span className="input-group-text">Menit</span></div>
        {withFeedbackLabel && (
          <FieldFeedbackLabel
            error={errors[field.name]}
            touched={touched[field.name]}
            label={label}
            type={type}
            customFeedbackLabel={customFeedbackLabel}
          />
        )}
      </div>
      
    </>
  );
}
