import React , {useState, useEffect} from 'react';
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../_metronic/_partials/controls";
import { Form,  Row, Col} from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from "formik";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../Master/_redux/pegawai/profileActions";

const PegawaiSchema = Yup.object().shape({
  nip: Yup.number()
    .required("NIP Wajib Diisi"),
  pass: Yup.string()
    .required("Password Wajib Diisi"),
  nama: Yup.string()
    .required("Nama Wajib Diisi"),
  tempat_lahir: Yup.string()
    .required("Tempat Lahir Wajib Diisi"),
  tgl_lahir: Yup.string()
    .required("Tanggal Lahir Wajib Diisi"),
  alamat: Yup.string()
    .required("Alamat Wajib Diisi"),
  eselon: Yup.number()
    .required("Eselon Wajib Diisi"),
  kelas_jab: Yup.number()
    .required("Kelas Jabatan Wajib Diisi"),
  pangkat_gol: Yup.number()
    .required("Pangkat Golongan Wajib Diisi"),
  pangkat_gol_tmt: Yup.string()
    .required("Pangkat Golongan TMT Wajib Diisi"),
  opd_id: Yup.number()
    .required("OPD Wajib Diisi"),
  jmlh_tpp: Yup.number()
    .required("Jumlah TPP Wajib Diisi"),
  tpp_plt: Yup.number()
    .required("TPP PLT TPP Wajib Diisi"),
  mode_tpp: Yup.number()
    .required("Mode TPP Wajib Diisi"),
  jmlh_hari_kerja: Yup.number()
    .required("Hari Kerja Wajib Diisi"),
});

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
  }));
  
export function ChangePassword(props) {
    const classes = useStyles();
    const [pegawai, setPegawai] = useState("");

    const {token} = useSelector(state => state.auth);
    const {user} = useSelector(state => state.auth);
    const options = {
      headers: {
        'token': `${token}`
      }
    };


    const dispatch = useDispatch();
    const {  customerForEdit } = useSelector(
      (state) => ({
        actionsLoading: state.customers.actionsLoading,
        customerForEdit: state.customers.customerForEdit
      }),
      shallowEqual,
    );
    
    useEffect(() => {
      dispatch(actions.detailPegawai(user.nip));
    }, [dispatch]);

    const handleChange = name => event => {
      if (pegawai === ""){
        setPegawai(customerForEdit);
      }else{
        setPegawai({...pegawai, [name]: event.target.value });
      }
      // console.log(name, "perubahan jadi :", event.target.value)
    };
    
    if(customerForEdit === undefined){
      return <></>;
    }

    console.log("---", user);

    return (
        <>
            <Card>
              <CardHeader title="Profile & Ganti Password">
              </CardHeader>
              <CardBody>
              <Formik
                validationSchema={PegawaiSchema}
                enableReinitialize={true}
                initialValues={pegawai}
                onSubmit={(values) => {
                  // saveCustomer(values);
                }}
              >
                {({ handleSubmit, handleBlur, values, touched, isValid, errors, }) =>  (
                  <>
                <Form autoComplete="off">
                  <div className="row">
                    <div className="col-sm-6">
                      <Form.Group as={Row} controlId="nip">
                        <div className="row col-sm-12">
                          <Form.Label column sm="4">
                            NIP
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control type="number" name="nip" placeholder="NIP" plaintext disabled={true} defaultValue={customerForEdit.nip} />
                          </Col>
                        </div>
                      </Form.Group>
                      <Form.Group as={Row} controlId="nama">
                        <div className="row col-sm-12">
                          <Form.Label column sm="4">
                            Nama
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control type="text" placeholder="Nama" name="nama" defaultValue={customerForEdit.nama} plaintext disabled={true} />
                          </Col>
                        </div>
                      </Form.Group>
                      <Form.Group as={Row} controlId="tempat_lahir">
                        <div className="row col-sm-12">
                          <Form.Label column sm="4">
                            Tempat Lahir
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control type="text" name="tempat_lahir" placeholder="Tempat Lahir" defaultValue={customerForEdit.tempat_lahir} plaintext disabled={true} />
                          </Col>
                        </div>
                      </Form.Group>
                      <Form.Group as={Row} controlId="tgl_lahir">
                        <div className="row col-sm-12">
                          <Form.Label column sm="4">
                            Tanggal Lahir
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control type="text" name="tgl_lahir_text" plaintext disabled={true} defaultValue={customerForEdit.tgl_lahir} />
                          </Col>
                        </div>
                      </Form.Group>
                      <Form.Group as={Row} controlId="alamat">
                        <div className="row col-sm-12">
                          <Form.Label column sm="4">
                            Alamat
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control as="textarea" rows={3} name="alamat" placeholder="Alamat" defaultValue={customerForEdit.alamat} plaintext disabled={true}/>
                          </Col>
                        </div>
                      </Form.Group>
                      <Form.Group as={Row} controlId="eselon">
                        <div className="row col-sm-12">
                          <Form.Label column sm="4">
                            Eselon
                          </Form.Label>
                          <Col sm="8">
                              <Form.Control type="text" name="eselon" plaintext disabled={true} defaultValue={customerForEdit.eselon_data} />
                          </Col>
                        </div>
                      </Form.Group>
                      <Form.Group as={Row} controlId="kelas_jab">
                        <div className="row col-sm-12">
                          <Form.Label column sm="4">
                            Kelas Jabatan
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control type="text" name="kelas_jab" plaintext disabled={true} defaultValue={customerForEdit.kelas_jab} />
                          </Col>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-sm-6">
                      <Form.Group as={Row} controlId="pangkat_gol">
                        <Form.Label column sm="4">
                          Pangkat Golongan
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="text" name="pangkat_gol" plaintext disabled={true} defaultValue={customerForEdit.pangkat_gol_data} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="pangkat_gol_tmt">
                        <Form.Label column sm="4">
                          Pangkat TMT
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control type="text" name="pangkat_gol_tmt_text" plaintext disabled={true} defaultValue={customerForEdit.pangkat_gol_tmt} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="opd_id">
                        <Form.Label column sm="4">
                          OPD
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="text" name="opd_id" plaintext disabled={true} defaultValue={customerForEdit.opd_data.opd_nama} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="jmlh_tpp">
                        <Form.Label column sm="4">
                          Jumlah Base TPP
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="text" name="jmlh_tpp" placeholder="Jumlah TPP" defaultValue={customerForEdit.jmlh_tpp} onChange={handleChange('jmlh_tpp')}  plaintext disabled={true} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="mode_tpp">
                        <Form.Label column sm="4">
                          Mode TPP
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="text" name="mode_tpp" placeholder="Mode TPP" defaultValue={customerForEdit.mode_tpp} onChange={handleChange('mode_tpp')}  plaintext disabled={true} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="jmlh_hari_kerja">
                        <Form.Label column sm="4">
                          Mode Hari Kerja
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="text" name="jmlh_hari_kerja" placeholder="Jumlah hari Kerja" defaultValue={customerForEdit.jmlh_hari_kerja} onChange={handleChange('jmlh_hari_kerja')} plaintext disabled={true} />
                        </Col>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="float-right">
                        <a href={"/master/pegawai"} className="btn btn-danger btn-xs mr-2" >
                          Batal
                        </a>
                        <button
                            type="button"
                            className="btn btn-success btn-xs"
                            onClick={() => handleSubmit()}
                          >
                          Simpan Perubahan Data
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
                </>
                  )}
                </Formik>
              </CardBody>
            </Card>
        </>
    );
}
