import React, {useEffect, useState} from "react";
import { Modal, Button } from "react-bootstrap";
import {saveTokenFirebase, checkVersion, checkInfo} from "../../../app/modules/Auth/_redux/authCrud";
import {
    MixedWidget1,
    ListsWidget9,
    ListsWidget1,
} from "../widgets";
import { messaging } from "../../../init-fcm";
import * as firebase from "firebase/app";
import "firebase/messaging";
import {
    isIOS
} from "react-device-detect";

require('dotenv').config()

const moment = require('moment')
moment.locale('ID')

export function Demo1Dashboard() {

    const [modalInfo, setModalInfo] = useState(false);
    const [info, setInfo] = useState({
        "_id": "",
        "updated_at": "",
        "information": "",
        "title": "",
        "publish": false
    });
    useEffect(() => {
        
        if (isIOS){
            if(firebase.messaging.isSupported()){
                messaging.requestPermission()
                .then(async function() {
                    // save token firebase 
                    let token = await messaging.getToken();
                    let dataSend = {
                        token : token
                    }
                    saveTokenFirebase(dataSend)
                    .then((result) => {
                        // let data = result.data
                        if (result.status === 200) {
                            //console.log('Token Firebase Ku : '+data.token)
                        }  
                    })
                })
                .catch(function(err) {
                    console.log("Unable to get permission to notify.", err);
                });
            }
        } else{
            try {
                messaging.requestPermission()
                .then(async function() {
                    // save token firebase 
                    let token = await messaging.getToken();
                    let dataSend = {
                        token : token
                    }
                    saveTokenFirebase(dataSend)
                    .then((result) => {
                        let data = result.data
                        if (result.status === 200) {
                            // console.log('Token Firebase Ku : '+data.token)
                        }
                        console.log("result token", result)
                    })
                })
                .catch(function(err) {
                    console.log("Unable to get permission to notify.");
                });
            } catch (error) {
                console.log("Token tidak terkirim ")
            }
        }
        checkVersion()
        .then((result) => {
            if (result.status === 200) {
                // console.log(result);
                setModalInfo(result.data.data.modal_info)
            }
        })

        checkInfo(1)
        .then((result) => {
            if (result.status === 200) {
                setInfo(result.data.data)
            }
        })
    }, []);

    // const handleClose = () => {
    //     setModalInfo(false);
    // };

    const handleClose = () => {
        setInfo({
            "publish": false
        });
    };

    function createMarkup() {
        return {__html: info.information};
      }
      
    return (<>
            <Modal show={info.publish} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{info.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={createMarkup()} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                Close
                </Button>
            </Modal.Footer>
            </Modal>
            <div className="row">
                
                <MixedWidget1 className="card-stretch gutter-b"/>
                <ListsWidget9 className="card-stretch gutter-b"/>
              
                {/* <div className="col-lg-3 col-xxl-3 order-3">
                    <ListsWidget9 className="card-stretch gutter-b"/>
                </div> */}

                {/* <div className="col-lg-6 col-xxl-4">
                    <StatsWidget11 className="card-stretch card-stretch-half gutter-b"/>
                    <StatsWidget12 className="card-stretch card-stretch-half gutter-b"/>
                </div> */}

                {/* <div className="col-xxl-8 order-2 order-xxl-1">
                    <AdvanceTablesWidget2 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget3 className="card-stretch gutter-b"/>
                </div> */}
                {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget4 className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                    <ListsWidget8 className="card-stretch gutter-b"/>
                </div> */}
            </div>
            {/* <div className="row">
                <div className="col-lg-4">
                    <MixedWidget14 className="card-stretch gutter-b" />
                </div>
                <div className="col-lg-8">
                    <AdvanceTablesWidget4 className="card-stretch gutter-b" />
                </div>
            </div> */}
    </>);
}
