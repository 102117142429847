// Mixed
export {MixedWidget1} from "./dashboardkinerja/MixedWidget1";
export {MixedWidget14} from "./mixed/MixedWidget14";

// Lists
export {ListsWidget1} from "./dashboardkinerja/ListsWidget1";
export {ListsWidget3} from "./lists/ListsWidget3";
export {ListsWidget4} from "./lists/ListsWidget4";
export {ListsWidget8} from "./lists/ListsWidget8";
export {ListsWidget9} from "./dashboardkinerja/ListsWidget9";
export { ListsWidget10 } from "./lists/ListsWidget10";
export { ListsWidget11 } from "./lists/ListsWidget11";
export { ListsWidget14 } from "./lists/ListsWidget14";

// Stats
export {StatsWidget11} from "./stats/StatsWidget11";
export {StatsWidget12} from "./stats/StatsWidget12";

// Advance tables
export {AdvanceTablesWidget2} from "./advance-tables/AdvanceTablesWidget2";
export {AdvanceTablesWidget4} from "./advance-tables/AdvanceTablesWidget4";
export {AdvanceTablesWidget7} from "./advance-tables/AdvanceTablesWidget7";
export {AdvanceTablesWidget9} from "./advance-tables/AdvanceTablesWidget9";
