import axios from "axios";

// export const CUSTOMERS_URL = "api/customers";
export const CUSTOMERS_URL = "https://ekinerja.rembangkab.go.id/api/api/v1/pegawai/activity";

function getOptions(){
  const storedData = localStorage.getItem('persist:eKinerjaLocal');
  let localData = JSON.parse(storedData);
  let options = {
    headers: {
      'token': "123"
    }
  };
  if (localData['token']) {
    const storedData2 = localStorage.getItem('persist:eKinerjaLocal');
    let localData2 = JSON.parse(storedData2);
    let authToken = JSON.parse(localData2['token']);
    options = {
      headers: {
        'token': `${authToken}`
      }
    };
    return options
  }else{
     options = {
      headers: {
        'token': "456"
      }
    };
    return options
  }
}

const tokenSayaExpired = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmNzFkM2E5MjQzYTAwMDBhZTAwNmU1YyIsIm5pcCI6IjE5NzcwOTIyMTk5NzAzMTAwMyIsImlhdCI6MTYwNDMyMTk5MCwiZXhwIjoxNjA2OTEzOTkwLCJhdWQiOiJVc2Vyc0lkZW50aXR5Iiwic3ViIjoic2Vzc2lvbkp3dFNhdmUifQ.GfkKFYhOg9vka9Iv77iXTdr9n_EXtcrJ9Ww3IpbL9d4";

function optionsTokenExpired() {
  let options = {
    headers: {
      'token': tokenSayaExpired
    }
  }
  return options;
};

// CREATE =>  POST: add a new customer to the server
export function createCustomer(aktivitas) {
  return axios.post(`${CUSTOMERS_URL}/create`, { aktivitas }, getOptions());
  // return axios.post(`${CUSTOMERS_URL}/create`, { aktivitas }, optionsTokenExpired);
}

// READ
export function getAllCustomers() {
  return axios.get(CUSTOMERS_URL);
}
export function getCustomerById(customerId) {
  return axios.get(`${CUSTOMERS_URL}/detail/${customerId}`, getOptions());
}
export function findCustomers(queryParams) {
  // return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
  return axios.post(`${CUSTOMERS_URL}/dataTable`, { queryParams }, getOptions());
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(aktivitas) {
  // return axios.post(`${CUSTOMERS_URL}/${customer.id}`, { customer });
  return axios.post(`${CUSTOMERS_URL}/update`, { aktivitas }, getOptions());
}

// UPDATE Status
export function updateStatusForCustomers(ids) {
  return axios.post(`${CUSTOMERS_URL}/update-custom`, {
    ids
  }, getOptions());
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
  return axios.get(`${CUSTOMERS_URL}/remove/${customerId}`, getOptions());
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
  return axios.post(`${CUSTOMERS_URL}/remove-custom/`, { ids }, getOptions());
}

export function sendCustomer(customerId) {  
  return axios.get(`${CUSTOMERS_URL}/kirim/${customerId}`, getOptions());
}
