import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
// import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {customersSlice} from "../app/modules/Data/_redux/aktivitas/customersSlice";
import {customersSliceBawahan} from "../app/modules/Data/_redux/aktivitasbawahan/customersSlice";
import {semuaAktivitasSlice} from "../app/modules/Data/_redux/aktivitas/semuaAktivitasSlice";
import {pegawaiSlice} from "../app/modules/Resume/custPeg/_redux/pegawaiSlice";
// import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
// import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
// master opd
import {operatorKinerja} from "../app/modules/Master/kinerjaOPD/_redux/DataSlice";
import {operatorPresensi} from "../app/modules/Master/presensiOPD/_redux/pegawaiSlice";
import {profileSlice} from "../app/modules/Master/_redux/pegawai/profileSlice";
import {adminopd} from "../app/modules/Adminpemkab/pages/opd/_redux/DataSlice";

















export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  customers: customersSliceBawahan.reducer,
  semuaAktivitas: semuaAktivitasSlice.reducer,
  pegawai: pegawaiSlice.reducer,
  // products: productsSlice.reducer,
  // remarks: remarksSlice.reducer,
  // specifications: specificationsSlice.reducer,
  // master opd
  adminopd: adminopd.reducer,
  operatorKinerja: operatorKinerja.reducer,
  operatorPresensi: operatorPresensi.reducer,
  //pegawai profile
  profileSlice: profileSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
